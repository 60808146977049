import { useEffect, useState } from 'react';
import { SectionTitle } from '../ui/SectionTitle';
import { twJoin } from 'tailwind-merge';
import { CourseSearch } from './courseSearch/CourseSearch';
import { AppState } from '@/types';
import { useSelector } from 'react-redux';

export function CourseSearchContainer() {
  const [expand, setExpand] = useState(true);

  const { manuallySelectedPack } = useSelector((state: AppState) => state.cart);

  // If a pack is manually selected, collapse the course search container
  useEffect(() => {
    if (manuallySelectedPack) {
      setExpand(false);
    }
  }, [manuallySelectedPack]);

  return (
    <section
      onClick={() => {
        if (!expand && !manuallySelectedPack) {
          setExpand(true);
        }
      }}
      className={twJoin(
        'border-2 border-primary/70 rounded-xl flex flex-col gap-0 overflow-hidden p-2 lg:p-2 2xl:p-4',
        expand ? '' : 'p-1 cursor-pointer'
      )}>
      {!expand && !manuallySelectedPack && <SectionTitle>Legg til fag og pakker</SectionTitle>}
      <CourseSearch expand={expand} />
      <button
        className={twJoin(
          'mt-auto self-center transition-all w-full flex items-center justify-center',
          expand ? 'rotate-180' : ''
        )}
        onClick={() => {
          if (manuallySelectedPack) {
            setExpand(true);
          }

          if (expand) {
            setExpand(false);
          }
        }}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-4 h-4 xl:h-5 xl:w-5'>
          <path strokeLinecap='round' strokeLinejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' />
        </svg>
      </button>
    </section>
  );
}
