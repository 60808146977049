import { PackInformation } from '@/helpers/GetText/GetText';
import { PackStructure } from '@/types';
import { useState } from 'react';
import { ReadMoreButton } from '../../ui/ReadMoreButton';

interface Props {
  pack: PackStructure;
}

export function PackPopupInfo({ pack }: Props) {
  const [showExtra, setShowExtra] = useState(false);
  const packInfo = PackInformation(pack.title);

  return (
    <div className='flex flex-col gap-2 text-sm text-center'>
      {packInfo[0] && <>{packInfo[0]}</>}
      {packInfo.slice(1).length > 0 && (
        <div className='flex items-center justify-center'>
          <ReadMoreButton readMore={showExtra} onClick={() => setShowExtra(!showExtra)} />
        </div>
      )}
      {showExtra && (
        <ol className='list-disc text-left ml-8 mr-8'>
          {packInfo.slice(1).map((extra, i) => (
            <li key={`${pack.alias}-pack-extra-${i}`}>{extra}</li>
          ))}
        </ol>
      )}
    </div>
  );
}
