import { PlacementType, StructuredCourse } from '@/types';
import { Modal } from '@/components/ui/Modal';
import { useState } from 'react';
import { CourseInformation } from '@/helpers/GetText/GetText';
import DOMPurify from 'dompurify';
import { getCourseRequirements } from '@/reducers/helpers';
import { ReadMoreButton } from '@/components/ui/ReadMoreButton';
import { CoursePopupExams } from './CoursePopupExams';

type Props = {
  course: StructuredCourse;
  onClose: () => void;
  mode: PlacementType;
};

export function CoursePopup({ course, onClose, mode = 'online' }: Props) {
  const [showInfo, setShowInfo] = useState(false);

  const isLab = course.courseCode.includes('lab-');
  const isIntro = course.courseCode.includes('intro-');

  const requirements = getCourseRequirements(course);


  return (
    <Modal id='course_popup' coursePopup={true} mode={mode} onClose={onClose}>
      <div className='text-sm'>
        <h2 className='text-center font-bold text-lg'>{course.courseTitle}</h2>
        <CoursePopupExams structuredCourse={course} />
        {(!isLab || !isIntro) && (
          <p>
            <strong>Fagkoder:</strong> {course.courseCode}
          </p>
        )}
        {requirements && (
          <p>
            <strong>Påkrevde forkunnskaper:</strong>{' '}
            <span>{requirements[0].courseNames.map((r, i) => (i > 0 ? ` eller ${r}` : r))}</span>
          </p>
        )}
        <ReadMoreButton readMore={showInfo} onClick={() => setShowInfo(!showInfo)} />
        {showInfo && (
          <ul className='list-disc text-left text-sm ml-12'>
            {CourseInformation(course.courseCode).map((info, i) => {
              const sanitizedInfo = typeof info === 'string' ? DOMPurify.sanitize(info) : '';
              return <li key={i} dangerouslySetInnerHTML={{ __html: sanitizedInfo }} />;
            })}
          </ul>
        )}
      </div>
    </Modal>
  );
}
