import { CourseVariants, ICourse, IPlaces, PlacementType, SemesterType, StructuredCourse } from '@/types';
import { AUTUMN_SEMESTERS, SPRING_SEMESTERS, YEAR_SEMESTERS } from '@/utils/constants';

// function to check if course is disabled based on placement and selected campus
export function isDisabled(course: StructuredCourse, placement: PlacementType, selectedCampus: IPlaces | null) {
  if (placement === 'live') {
    if (course.places.includes('sondig')) {
      return false;
    }
  }
  if (placement === 'campus') {
    if (hasNoCampusVariants(course)) {
      return true;
    }

    if (selectedCampus === null) {
      return false;
    }
    if (course.places.includes(selectedCampus.alias)) {
      return false;
    }
  }

  if (placement === 'online') {
    if (course.places.includes('sonans')) {
      return false;
    }
  }

  return true;
}

export function hasVariants(course: StructuredCourse, selectedCampus: IPlaces | null) {
  let hasVariants = false;
  course.variants.forEach((variant) => {
    if (variant.type === 'campus') {
      if (selectedCampus) {
        if (variant.places.includes(selectedCampus.alias)) {
          hasVariants = true;
        }
      } else {
        hasVariants = true;
      }
    } else if (variant.type === 'live') {
      if (variant.places.includes('sondig')) {
        hasVariants = true;
      }
    } else if (variant.type === 'online') {
      if (variant.places.includes('sonans')) {
        hasVariants = true;
      }
    }
  });
  return hasVariants;
}

export function isAvailableOnCurrentCampus(course: StructuredCourse, selectedCampus: IPlaces) {
  return course.places.includes(selectedCampus.alias);
}

export function hasNoCampusVariants(course: StructuredCourse) {
  const campusVariants = course.variants.filter((variant) => variant.type === 'campus');
  return campusVariants.length === 0;
}

export function translateSemester(semester: SemesterType) {
  switch (semester) {
    case SPRING_SEMESTERS.find((s) => s === semester):
      return 'vår';
    case AUTUMN_SEMESTERS.find((s) => s === semester):
      return 'høst';
    case YEAR_SEMESTERS.find((s) => s === semester):
      return 'helår';
    default:
      return '';
  }
}

export function structureCourse(packCourse: ICourse) {
  const course: StructuredCourse = {
    courseCode: packCourse.course_code,
    courseTitle: packCourse.title.replace(' live', '').replace(' online', ''),
    labCourses: [],
    places: packCourse.places,
    times: [packCourse.time],
    upgradeCourses: [],
    upgradePlaces: [],
    variants: [],
    category: '',
    mathCourse: false,
    vocationalSubject: false,
  };

  return course;
}

export function structureSelection(packCourse: ICourse) {
  const selection: CourseVariants = {
    courseCode: packCourse.course_code,
    courseId: packCourse.course_id,
    courseTitle: packCourse.title.replace(' live', '').replace(' online', ''),
    places: packCourse.places,
    price: packCourse.price,
    priceNetFullyear: packCourse.online_year_price,
    priceNetHalfyear: packCourse.online_half_year_price,
    time: packCourse.time as SemesterType,
    yearHours: packCourse.year_hours,
    type: packCourse.placement as Exclude<PlacementType, ''>,
  };

  return selection;
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Resolve variant type for tag color in course search
 */
export function resolveVariantType({
  course,
  selectedPlacement,
  selectedCampus,
  preferredPlacement,
}: {
  course: StructuredCourse;
  selectedPlacement: PlacementType;
  selectedCampus: IPlaces | null;
  preferredPlacement: PlacementType;
}) {
  const variantTypes = [...new Set(course.variants.map((variant) => variant.type))];

  // if selectedPlacement is set, return that
  if (selectedPlacement) {
    return selectedPlacement;
  }

  // if only one variant type, return that
  if (variantTypes.length === 1) {
    return variantTypes[0];
  }

  // if preferredPlacement is set and is in the variantTypes
  if (preferredPlacement === 'campus' && variantTypes.includes('campus')) {
    if (selectedCampus) {
      if (isAvailableOnCurrentCampus(course, selectedCampus)) {
        return 'campus';
      } else if (variantTypes.includes('live')) {
        return 'live';
      } else {
        return 'online';
      }
    }
  }

  if (preferredPlacement === 'live') {
    if (variantTypes.includes('live')) {
      return 'live';
    } else {
      return 'online';
    }
  }

  if (preferredPlacement === 'online') {
    if (variantTypes.includes('online')) {
      return 'online';
    } else {
      return 'live';
    }
  }

  return 'online';
}
