import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Container } from './Container';

const links = [
  {
    title: 'Info',
    url: '/',
  },
  {
    title: 'Fag og priser',
    url: '/fag',
  },
  {
    title: 'Påmelding',
    url: '/paamelding',
  },
];

export function Navbar() {
  const location = useLocation();

  return (
    <Container>
      <nav className='flex justify-center py-2 2xl:py-4 text-sm 2xl:text-base'>
        <ul className='flex rounded-full bg-primary/50 border-2 border-primary/10 relative items-center'>
          {links.map((item) => (
            <li
              key={item.url}
              className='rounded-full text-center w-[6rem] lg:w-[8rem] overflow-hidden cursor-pointer relative z-10'>
              <NavLink
                className={(link) =>
                  twMerge(
                    link.isActive ||
                      (location.pathname === '/bestill' && item.url === '/paamelding') ||
                      (location.pathname === '/bekreftet' && item.url === '/paamelding')
                      ? ''
                      : '',
                    'w-full h-full block transition-all'
                  )
                }
                to={item.url}>
                {item.title}
              </NavLink>
            </li>
          ))}
          <div
            className={twMerge(
              'bg-white absolute w-[6rem] lg:w-[8rem] h-full rounded-full transition-all',
              location.pathname === '/' && 'translate-x-0',
              location.pathname === '/fag' && 'translate-x-[100%]',
              (location.pathname === '/bestill' ||
                location.pathname === '/paamelding' ||
                location.pathname === '/bekreftet') &&
                'translate-x-[200%]'
            )}></div>
        </ul>
      </nav>
    </Container>
  );
}
