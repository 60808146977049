import React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <input
      ref={ref}
      className="rounded-lg border text-sm border-neutral-300 w-full placeholder:text-neutral-400"
      {...props}
    />
  );
});

export { Input };