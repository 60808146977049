import { XMarkIcon } from '@heroicons/react/16/solid';
import { createPortal } from 'react-dom';
import { twJoin, twMerge } from 'tailwind-merge';
import { useModal } from './useModal';
import { PlacementType } from '@/types';

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  mode: PlacementType;
  coursePopup?: boolean;
  className?: string;
  id?: string;
}

export function Modal({ children, onClose, mode = 'campus', coursePopup = false, className = '', id = '' }: Props) {
  const modeClasses = {
    '': 'border-secondary',
    campus: 'border-primary',
    live: 'border-tertiary',
    online: 'border-secondary',
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { modalContentRef } = useModal({ onClose });

  return createPortal(
    <div className={twMerge('fixed top-0 left-0 w-[100vw] h-[100vh] z-50 flex items-center justify-center', className)}>
      <div onClick={onClose} className='absolute bg-black/20 w-full h-full'></div>
      <div
        id={id}
        ref={modalContentRef}
        className={twJoin(
          'bg-white relative w-[95%] lg:w-[40rem] max-h-[95svh] border-2 rounded-xl shadow-xl animate-scale',
          coursePopup ? 'border-l-[2rem]' : '',
          modeClasses[mode]
        )}>
        <div className='w-full flex justify-end pt-2 pr-2'>
          <button onClick={onClose}>
            <XMarkIcon className='w-6' />
          </button>
        </div>
        <div className='flex flex-col gap-2 px-4 xl:px-8 pb-8'>{children}</div>
      </div>
    </div>,
    document.querySelector('#overlay') as HTMLElement
  );
}
