import { useEffect } from 'react';
import { twJoin } from 'tailwind-merge';

interface Props {
  className?: string;
}

export function BookCounseling({ className = '' }: Props) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://public-sonans-bucket.s3.eu-west-1.amazonaws.com/sonans-serveify-a5.js';
    document.body.appendChild(script);
  }, []);

  return <div id='root' className={twJoin("book-after-purchase mb-20", className)}></div>;
}
