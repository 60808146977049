import { IStudyLoad } from '@/types';
import {Icon} from "@/components/ui/Icon.tsx";

interface Props {
  studyLoad: IStudyLoad;
}

export function StudyLoad({ studyLoad }: Props) {
  
  return (
    <div className='w-full font-light bg-primary/60 rounded-b-xl text-center'>
      <div className='flex justify-between'>
        <div className='flex flex-col xl:flex-row w-full justify-around gap-0 divide-x-1 text-sm xl:text-base divide-y xl:divide-y-0 xl:divide-x divide-black/40 p-2 xl:gap-4 '>
          <p className='w-full'>
            {studyLoad?.exams.length} eksamen{studyLoad?.exams.length > 1 || studyLoad?.exams.length === 0 ? 'er' : ''}
          </p>
          <p className='w-full flex justify-center items-center gap-2'>
            {studyLoad?.percent}% {studyLoad?.fullTime ? 'fulltid' : 'deltid'}{' '}
            {studyLoad?.warning && <Icon className='w-4' type='warning-outline' />}
          </p>
        </div>
      </div>
    </div>
  );
}
