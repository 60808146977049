import { AppDispatch, AppState, selectedCourses } from '@/types';
import { twJoin, twMerge } from 'tailwind-merge';
import { CourseInfoTag } from './CourseInfoTag';
import { XMarkIcon } from '@heroicons/react/16/solid';
import FormatPrice from '@/helpers/Functions/FormatPrice';
import { useState } from 'react';
import { ShortenCourseName } from '@/helpers/Functions/ShortenCourseName';
import { CourseCartPopup } from './popups/CourseCartPopup';
import { useDispatch, useSelector } from 'react-redux';
import { CourseRequirements } from '@/helpers/GetText/GetText';
import { Modal } from '../ui/Modal';
import { Button } from '../ui/Button';
import { addToCart } from '@/reducers/cart';
import { Icon } from '@/components/ui/Icon.tsx';
interface Props {
  course: selectedCourses;
  className?: string;
  style?: React.CSSProperties;
  onRemoveCourse: (course: selectedCourses) => void;
}

export function CourseCartItem({ className = '', course, style = {}, onRemoveCourse }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [showRequirementsModal, setShowRequirementsModal] = useState(false);

  const semesters = useSelector((state: AppState) => state.semester.semesters);
  const selectedCourses = useSelector((state: AppState) => state.cart.selectedCourses);
  const structuredCourses = useSelector((state: AppState) => state.courses.structuredCourses);
  const selectedCampus = useSelector((state: AppState) => state.cart.selectedCampus);

  const dispatch = useDispatch<AppDispatch>();

  const borderColors = {
    online: 'border-secondary',
    campus: 'border-primary',
    live: 'border-tertiary',
  };

  const backgroundColor = {
    campus: 'bg-primary',
    online: 'bg-secondary',
    live: 'bg-tertiary',
  };

  const accessToday =
    course.type === 'online' &&
    (semesters[0].availableTimes.includes(course.selectedSemester) || course.selectedSemester === 'year');

  // Check if course has lab
  const hasLab = course.labCourses.length > 0;
  // Check if user has selected lab
  const hasSelectedLab = selectedCourses.find((sel) => sel.courseCode === `lab-${course.courseCode}`);

  const req = CourseRequirements(course.courseCode);
  const selectedCourseCodes = selectedCourses.map((c) => c.courseCode);
  const requirementsMet = req ? selectedCourseCodes.some((cc) => req[0]?.courseCodes.includes(cc)) : true;

  function missingRequirements() {
    if (requirementsMet && hasSelectedLab && hasLab) {
      return false;
    } else if (hasLab && !hasSelectedLab && course.type === 'online') {
      return false;
    } else if (!requirementsMet || (hasLab && !hasSelectedLab)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div
      data-testid={'cart-item-' + course.courseCode}
      style={style}
      className={twMerge(
        'bg-white relative rounded-lg h-[3rem] lg:h-[4rem] 2xl:h-[5rem] border border-neutral-300 flex justify-between items-start overflow-hidden gap-2 shadow-md z-20 animate-reveal',
        course.packCourse ? borderColors[course.type] : '',
        className
      )}>
      <button
        onClick={(e) => {
          if (e.target instanceof HTMLParagraphElement) {
            if (e.target.textContent === 'OBS!') {
              setShowRequirementsModal(true);
              return;
            }
          }
          setShowModal(true);
        }}
        className='h-full w-full flex items-start gap-1 lg:gap-2'>
        <CourseInfoTag placement={course.type} />
        <div className='text-left w-full pt-0 lg:pt-2'>
          <div className='flex items-center gap-1'>
            <p className='font-medium'>{ShortenCourseName(course.course.courseTitle)}</p>
            {missingRequirements() && <p className='underline text-xs font-bold'>OBS!</p>}
          </div>
          <p className={twMerge('text-neutral-500', course.packCourse ? 'line-through' : '')}>
            {FormatPrice(course.selectedPrice)}
            {',-'}
          </p>
        </div>
        {course.selectedSemester === 'year' && (
          <span
            className={twJoin(
              backgroundColor[course.type],
              'w-12 lg:w-16 shrink-0 grow-0 self-start mt-1 px-1 rounded-md gap-1 flex items-center justify-center text-[0.5rem] lg:text-xs'
            )}>
            <Icon className='w-2' type='arrow-thin-right' />
            DEL 1
          </span>
        )}
        {/* {accessToday && (
          <span
            className={twJoin(
              backgroundColor[course.type],
              'w-20 absolute right-0 bottom-0 lg:relative lg:w-24 shrink-0 grow-0 self-start mt-1 px-1 rounded-md gap-1 flex items-center justify-center text-[0.5rem] lg:text-xs'
            )}>
            Tilgang i dag!
          </span>
        )} */}
      </button>
      <button
        className='self-start shrink-0 p-[0.2rem]'
        onClick={() => {
          onRemoveCourse(course);
        }}>
        <XMarkIcon className='w-6' />
      </button>
      {showModal && <CourseCartPopup course={course} onClose={() => setShowModal(false)} />}
      {showRequirementsModal && (
        <Modal id='course_requirements_popup' mode='campus' onClose={() => setShowRequirementsModal(false)}>
          <div className='text-center'>
            <h3 className='font-bold text-lg'>Viktig info om {course.selectedCourse.courseTitle}</h3>
            {!requirementsMet && req && <p className='font-semibold'>Påkrevde forkunnskaper:</p>}
            {!requirementsMet && req && (
              <p className='mb-5'>{req[0].courseNames.map((r, i) => (i > 0 ? ` eller ${r}` : r))}</p>
            )}
            {hasLab && !hasSelectedLab && <p className='font-semibold'>Labkurs:</p>}
            {hasLab && !hasSelectedLab && (
              <p className='mb-5'>
                Labøvelser er en del av muntlig-praktisk eksamen i dette faget, og legger grunnlaget for en tredjedel av
                din karakter. Labkurset er ikke obligatorisk, men vi anbefaler at du tar det for å være best mulig
                forberedt.
              </p>
            )}
            {hasLab && !hasSelectedLab && (
              <Button
                onClick={() => {
                  const selSemester =
                    course.selectedSemester === 'spring' ? 'lab_practice_spring' : 'lab_practice_autum';

                  const labCourse = structuredCourses.find((c) => c.courseCode === `lab-${course.courseCode}`);

                  if (!labCourse) return;

                  dispatch(addToCart(labCourse, course.type, selectedCourses, selSemester, false, selectedCampus));
                  setShowRequirementsModal(false);
                }}
                width='auto'
                mode='secondary'>
                Legg til lab
              </Button>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}
