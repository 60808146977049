import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/Input';
import { PageTitle } from '@/components/ui/PageTitle';
import { AppState, PackStructure, selectedCourses } from '@/types';
import axios from 'axios';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * Form for the user to fill out their contact information and place order
 */
export function Form() {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [pid, setPid] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [confirmEmail, setConfirmEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [postCode, setPostCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [accepted, setAccepted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [contractAlias, setContractAlias] = useState<string>('');
  const [lockSubmit, setLockSubmit] = useState<boolean>(true);
  const [recaptcha, setRecaptcha] = useState<string>('');

  const captchaRef = useRef<ReCAPTCHA>(null);

  const { selectedCourses, selectedPack, selectedCampus } = useSelector((state: AppState) => state.cart);

  const navigate = useNavigate();

  async function sendForm(event: React.FormEvent<HTMLFormElement>) {
    setLockSubmit(true);

    // const prevSems = ['yearspring', 'spring', 'lab_practice_spring', 'introspring'];

    event.preventDefault();

    if (email !== confirmEmail) {
      setErrorMessage('Epostene matcher ikke');
      return;
    }
    if (selectedCourses.length === 0) {
      setErrorMessage('Du må legge fag i handlekurven for å bestille');
      return;
    }
    const formData = new FormData();
    formData.append('name', `${firstName} ${lastName}`);
    formData.append('birth_number', pid);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('address', address);
    formData.append('zipcode', postCode);
    formData.append('city', city);
    formData.append('seeking_guidance', 'no');
    formData.append('cansign', accepted === true ? 'yes' : 'no');
    formData.append('paymentOption', 'invoice-mail');
    formData.append('user_comment', '');
    formData.append('click_id', 'false');
    selectedCourses.forEach((c, i) => {
      formData.append(`course_order[${i}][course_id]`, c.selectedCourse.courseId);
      formData.append(`course_order[${i}][time]`, c.selectedTime);
      formData.append(`course_order[${i}][alternative_type]`, c.selectedSemester);
      formData.append(`course_order[${i}][netcourse]`, c.selectedTime === 'netbased' ? 'true' : 'false');
      // if (c.earlyStart || (prevSem && prevSems.indexOf(c.selectedSemester) !== -1)) {
      //   formData.append(`course_order[${i}][earlyStart]`, 'true');
      // }
      if (c.packCourse === true && c.pack !== 'sonanspack') {
        formData.append(`course_order[${i}][ispackage]`, '1');
      }
      formData.append(`course_order[${i}][classLetter]`, '');
      formData.append(`course_order[${i}][semester]`, '2015/2016');
    });

    if (selectedPack) {
      if (selectedPack.alias !== 'sonanspack') {
        formData.append('package_order', selectedPack.lookup.text);
      }
    }

    const url = `${import.meta.env.VITE_API_URL}order/${contractAlias}/account`;

    if (!recaptcha) return;

    formData.append('captcha-token', recaptcha);

    try {
      await axios({
        method: 'post',
        url: url,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setLockSubmit(false);
      navigate('/bekreftet');
    } catch (e) {
      captchaRef.current?.reset();
      setErrorMessage('Noe gikk galt, sjekk informasjon og prøv igjen.');
      setLockSubmit(false);
    }
  }

  function changePid(event: SyntheticEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputType = (event as unknown as InputEvent).inputType;

    if (inputType === 'deleteContentBackward' || inputElement.value.match(/^\d*$/)) {
      setPid(inputElement.value);
    }
  }

  function changeNumber(event: React.ChangeEvent<HTMLInputElement>) {
    const inputElement = event.target as HTMLInputElement;
    const inputType = (event as unknown as InputEvent).inputType;

    if (inputType === 'deleteContentBackward' || inputElement.value.match(/^\d*$/)) {
      setPhone(inputElement.value);
      if (inputElement.value.length !== 8) {
        setLockSubmit(true);
      }
    }
  }

  function changeZip(event: SyntheticEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputType = (event as unknown as InputEvent).inputType;

    if (inputType === 'deleteContentBackward' || inputElement.value.match(/^\d*$/)) {
      setPostCode(inputElement.value);
    }
  }

  function AliasCorrection(courses: selectedCourses[], pack: PackStructure | null, alias: string) {
    const courseTypes: string[] = [];
    courses.forEach((c) => {
      if (courseTypes.indexOf(c.type) === -1) {
        courseTypes.push(c.type);
      }
    });

    if (pack) {
      if (courseTypes.indexOf(pack.placement) === -1) {
        courseTypes.push(pack.placement);
      }
    }
    let contractType = alias;
    if (courseTypes.indexOf('campus') !== -1) {
      contractType = alias;
    } else if (courseTypes.indexOf('live') !== -1) {
      contractType = 'sondig';
    } else {
      contractType = 'sonng';
    }
    setContractAlias(contractType);
  }

  useEffect(() => {
    const normalCourses = selectedCourses.filter((c: selectedCourses) => c.packCourse === false);
    AliasCorrection(normalCourses, selectedPack, selectedCampus ? selectedCampus.alias : 'sonans');
  }, []);

  useEffect(() => {
    setErrorMessage('');

    const isValid =
      firstName &&
      lastName &&
      pid &&
      email &&
      confirmEmail &&
      phone.length === 8 &&
      address &&
      postCode &&
      city &&
      accepted &&
      recaptcha;

    if (isValid) {
      setLockSubmit(false);
    } else {
      setLockSubmit(true);
    }
  }, [firstName, lastName, pid, email, confirmEmail, phone, address, postCode, city, accepted, recaptcha]);

  return (
    <div className='flex flex-col gap-2 mb-16'>
      <PageTitle>Kontaktinformasjon</PageTitle>
      <form onSubmit={sendForm}>
        <div className='flex flex-col gap-2'>
          {errorMessage !== '' && <div className='border border-tertiary bg-tertiary/50'>{errorMessage}</div>}
          <div className='flex flex-col xl:flex-row gap-2'>
            <Input
              name='firstName'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type='text'
              placeholder='Fornavn'
              required
            />
            <Input
              name='lastName'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type='text'
              placeholder='Etternavn'
              required
            />
          </div>
          <div>
            <Input
              name='pid'
              value={pid}
              onChange={(e) => changePid(e)}
              type='text'
              pattern='^[0-9]*$'
              minLength={11}
              maxLength={11}
              placeholder='Fødselsnummer'
              required
            />
          </div>
          <div className='flex flex-col xl:flex-row gap-2'>
            <Input
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type='email'
              placeholder='E-post'
              required
            />
            <Input
              name='confirmEmail'
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              type='email'
              placeholder='Bekreft e-post'
              required
            />
          </div>
          <div>
            <Input
              name='phone'
              value={phone}
              onChange={(e) => changeNumber(e)}
              type='text'
              pattern='^[0-9][0-9]*$'
              minLength={8}
              maxLength={8}
              placeholder='Mobilnummer'
              required
            />
          </div>
          <div>
            <Input
              name='address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type='text'
              placeholder='Adresse'
              required
            />
          </div>
          <div className='flex flex-col xl:flex-row gap-2'>
            <Input
              name='postCode'
              value={postCode}
              onChange={(e) => changeZip(e)}
              type='text'
              pattern='[0-9]*'
              minLength={4}
              maxLength={4}
              placeholder='Postnummer'
              required
            />
            <Input
              name='city'
              value={city}
              onChange={(e) => setCity(e.target.value)}
              type='text'
              placeholder='Poststed'
              required
            />
          </div>
          <div className='w-full text-center lg:w-[30rem] mx-auto'>
            <p>
              Ønsker du at en rådgiver gjennomgår fagvalgene med deg? På neste side kan du avtale en tid som passer.
            </p>
          </div>
          <div className='border border-tertiary bg-tertiary/40 rounded-lg p-4 w-full text-center lg:w-[40rem] mx-auto'>
            <Input
              className='w-4 rounded-md mr-2'
              name='accepted'
              checked={accepted}
              onChange={(e) => setAccepted(e.target.checked)}
              type='checkbox'
              id='accepted'
              required
            />
            <label htmlFor='accepted'>
              Jeg har lest og{' '}
              <a className='underline' target='_blank' href='https://sonans.no/vilkar-og-betingelser'>
                godtatt vilkårene
              </a>{' '}
              for bestilling av fag hos Sonans. Jeg godtar{' '}
              <a className='underline' target='_blank' href='https://sonans.no/vilkar-og-betingelser'>
                vilkårene
              </a>{' '}
              derav 14 dagers angrerett, bindende påmelding og eventuelle avbruddsgebyr.
            </label>
          </div>
          <div>
            <ReCAPTCHA
              className='[&>div>div]:mx-auto'
              ref={captchaRef}
              onChange={(value) => {
                if (!value) return;

                setRecaptcha(value);
              }}
              sitekey='6LfpljQqAAAAAPuqgmaAFee21KLzNtAcYP2DZvdW'
            />
          </div>
          <div className='w-full bg-white fixed 2xl:relative bottom-0 left-0 mt-4 pt-2 pb-2 flex items-center justify-center'>
            <Button width='auto' disabled={lockSubmit} type='submit' mode='secondary'>
              Send bestilling
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
