import { Button } from '@/components/ui/Button';
import { PageTitle } from '@/components/ui/PageTitle';
import { PackLine } from '@/components/verification/PackLine';
import { VerificationInfoBoxes } from '@/components/verification/VerificationInfoBoxes';
import { VerificationLine } from '@/components/verification/VerificationLine';
import FormatPrice from '@/helpers/Functions/FormatPrice';
import { beginCheckoutGTAG } from '@/helpers/gtag/checkout';
import { AppState, selectedCourses } from '@/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


export function Verification() {
  const [sonansPack, setSonansPack] = useState<boolean>(false);

  const { selectedCourses, selectedPack, total, totalBefore } = useSelector((state: AppState) => state.cart);

  const packCourses = selectedCourses.filter((c: selectedCourses) => c.packCourse === true && c.pack !== 'sonanspack');
  const courses = selectedCourses.filter(
    (c: selectedCourses) => c.packCourse === false || (c.packCourse == true && c.pack === 'sonanspack')
  );

  const fullCourses = courses.filter(
    (c) => c.packCourse === false && !c.courseCode.includes('intro-') && !c.courseCode.includes('lab-')
  );

  useEffect(() => {
    let packAlias = '';
    if (selectedPack) {
      packAlias = selectedPack.alias;
    }

    if (fullCourses.length > 5 || packAlias === 'sonanspack') {
      setSonansPack(true);
    }
  }, [selectedPack, fullCourses]);

  useEffect(() => {
    beginCheckoutGTAG(courses, selectedPack ? [selectedPack] : [], 'begin_checkout', total);
  }, []);

  return (
    <div className='flex flex-col gap-2 2xl:gap-4 pb-[12rem]'>
      <PageTitle>Sikre deg din studieplass nå</PageTitle>
      <div className='flex flex-col divide-y-[0.05rem] divide-neutral-300'>
        {selectedPack && selectedPack.alias !== 'sonanspack' && <PackLine pack={selectedPack} />}
        {packCourses.map((c) => (
          <VerificationLine key={c.courseCode + '-verification-line-pc'} course={c} />
        ))}
        {packCourses.length > 0 && courses.length > 0 && (
          <div className=' bg-primary/50 p-2'>
            <p className='text-left font-bold'>Øvrige fag:</p>
          </div>
        )}
        {courses.map((c) => (
          <VerificationLine key={c.courseCode + '-verification-line-c'} course={c} />
        ))}
        {sonansPack && (
          <div className=' bg-primary/50 w-full flex justify-between items-center p-2'>
            <p className='text-left text-lg font-bold'>Sonanspakken</p>
            <p className='w-full text-right font-semibold'>15% rabatt</p>
          </div>
        )}
      </div>
      <div className='w-full border-b border-neutral-700 border-dashed mt-2 mb-2'></div>
      {total !== totalBefore && (
        <div className='w-full flex pl-2 pr-2'>
          <p className='w-full text-right text-xl font-semibold'>
            <span className='text-sm line-through text-text-grey'>{FormatPrice(totalBefore)},-</span>
          </p>
        </div>
      )}
      <div className='w-full flex pl-2 pr-2'>
        <p className='text-base 2xl:text-xl font-semibold w-full text-left'>Totalt</p>
        <p className='w-full text-right text-xl font-semibold'>
          <span className='bg-secondary text-base 2xl:text-xl'>{FormatPrice(total)},-</span>
        </p>
      </div>
      <p className='text-left pl-2 pr-2'>
        *I tillegg til kursavgiften vil det påløpet en semesteravgift på 975,- per semester. Dette gjelder ikke ved kjøp
        av kun labkurs eller forkurs.
      </p>
      <div className='w-full mt-8 mb-4'>
        <VerificationInfoBoxes />
      </div>
      {selectedCourses.length > 0 && (
        <div className='w-full bg-white fixed 2xl:relative bottom-0 left-0 mt-4 pt-2 pb-2 flex items-center justify-center'>
          <Link to='/bestill'>
            <Button width='auto' mode='secondary'>
              Neste
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}
