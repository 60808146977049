import { PackStructure } from '@/types';
import dataLayer from '@/utils/dataLayer';

export function viewPackGTAG({ pack }: { pack: PackStructure }) {  
  if (pack.alias !== 'sonans_pack') {
    const event = {
      event: 'view_item',
      currency: 'NOK',
      value: pack.price,
      items: [
        {
          item_name: pack.title,
          discount: 0,
          item_type: '',
          item_brand: 'Sonans',
          item_category: 'pakke',
          item_id: pack.lookup.text,
          price: pack.price,
          quantity: 1,
        },
      ],
    };
    dataLayer.push(event);
  }
}
