import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, IExamDates } from '@/types';

interface ExamsState {
  exams: IExamDates[];
  isLoading: boolean;
  error: string;
}

const initialState: ExamsState = {
  exams: [],
  isLoading: false,
  error: '',
};

const exams = createSlice({
  name: 'exams',
  initialState,
  reducers: {
    setExams(state, action: PayloadAction<IExamDates[]>) {
      state.exams = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const examsReducer = exams.reducer;

export const { setExams, setIsLoading, setError } = exams.actions;

export function fetchExams() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(setIsLoading(true));
      const { data } = await axios.get(process.env.REACT_APP_WEB_API_URL + 'exam-dates');
      dispatch(setExams(data));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}
