import { XMarkIcon } from '@heroicons/react/16/solid';

interface Props {
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
  onClick: () => void;
}

export function VerificationInfoFull({ icon, title, description, onClick }: Props) {
  return (
    <article className='border-2 border-primary p-4 rounded-lg w-full flex gap-4 animate-reveal'>
      <div className='flex align-center'>{icon}</div>
      <div className='w-full flex flex-col gap-2'>
        <div className='flex justify-between items-center'>
          <h3 className='text-base lg:text-xl font-semibold'>{title}</h3>
          <button onClick={onClick}>
            <XMarkIcon className='w-6' />
          </button>
        </div>
        <div>{description}</div>
      </div>
    </article>
  );
}
