import { ICourse, ICourses } from "@/types";

export function CourseFromId(courseId: string, semester: string, courses: ICourses[]) {
    const courseArr: ICourse[] = []
    
    courses.forEach((category) => {
        category.subjects.forEach((subject) => {
            courseArr.push(subject)
        })
    })

    const found = courseArr.find((course) => course.course_id === courseId && course.time === semester)
    if(found) {
        return found
    } else {
        return 'not found'
    }
}