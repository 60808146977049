interface Props {
  content: string;
  highlight: string;
}

export function Highlight({ content, highlight }: Props) {
  const parts = content.split(' ');

  return (
    <span className='flex gap-1'>
      {parts.map((part, i) => {
        if (part.toLowerCase() === highlight.toLowerCase())
          return (
            <span className='relative' key={i}>
              <span className="absolute bg-primary h-2 w-full top-[50%] translate-y-[-50%]"></span>
              <span className="relative">{part}</span>
            </span>
          );
        return <span key={i}>{part}</span>;
      })}
    </span>
  );
}
