import Campus from '@/assets/campus-v2.svg?react';
import Live from '@/assets/live-v2.svg?react';
import Online from '@/assets/online-v2.svg?react';
import { setPerferredPlacement } from '@/reducers/cart';
import { AppDispatch, AppState, PlacementType } from '@/types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { twJoin } from 'tailwind-merge';
import { PlacesDropdown } from '../../places/PlacesDropdown';

export interface ISchool {
  name: string;
  alias: Exclude<PlacementType, ''>;
  color: string;
  placement: Exclude<PlacementType, ''>;
  background: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  info: React.ReactNode;
}

export const schools: ISchool[] = [
  {
    name: 'Campus',
    alias: 'campus',
    color: 'bg-primary',
    placement: 'campus',
    background: 'bg-primary/40',
    Icon: Campus,
    info: (
      <>
        <h3>Oppmøte i klasserom til faste tider.</h3>
        <ul>
          <li>Campuser i 9 byer</li>
          <li>Strukturert studiehverdag</li>
          <li>Grupperom og leseplasser</li>
          <li>Sosialt studentmiljø</li>
          <li>Arrangementer</li>
          <li>Leksehjelp</li>
        </ul>
      </>
    ),
  },
  {
    name: 'Live',
    alias: 'live',
    color: 'bg-tertiary',
    placement: 'live',
    background: 'bg-tertiary/40',
    Icon: Live,
    info: (
      <>
        <h3>Livestreamet til faste tider.</h3>

        <ul>
          <li>Fleksibilitet og struktur</li>
          <li>Direkte videoundervisning med fast timeplan</li>
          <li>Repeter med opptak</li>
          <li>Studer fra hvor du vil</li>
          <li>Mulighet for aktiv deltakelse i undervisningen</li>
          <li>Skreddersydd for et godt digitalt læringsmiljø</li>
        </ul>
      </>
    ),
  },
  {
    name: 'Online',
    alias: 'online',
    color: 'bg-secondary',
    placement: 'online',
    background: 'bg-secondary/40',
    Icon: Online,
    info: (
      <>
        <h3>Når du vil, med lærerstøtte.</h3>
        <ul>
          <li>Studer i eget tempo</li>
          <li>Mange selvtester og valgfrie innsendingsoppgaver</li>
          <li>Videoundervisning i kombinasjon med fagteori</li>
          <li>Pedagogisk læreplattform; full oversikt over pensum</li>
          <li>Faglærer hjelper deg</li>
        </ul>
      </>
    ),
  },
];

export function CourseSearchSemesterIcons() {
  const [showSelectCampus, setShowSelectCampus] = useState(false);
  const { selectedCampus } = useSelector((state: AppState) => state.cart);

  const dispatch = useDispatch<AppDispatch>();

  function displayName(school: ISchool) {
    if (school.placement === 'campus') {
      return selectedCampus?.shortName || school.name;
    } else {
      return school.name;
    }
  }

  return (
    <div className='flex gap-1'>
      {schools.map((school) => (
        <button
          id='course-search-semester-icons'
          onClick={() => {
            if (school.placement === 'campus') {
              if (!showSelectCampus) {
                setShowSelectCampus(true);
              }
            } else {
              dispatch(setPerferredPlacement(school.placement));
            }
          }}
          key={school.alias}
          className={twJoin('relative flex flex-col items-center justify-center rounded-t-lg', school.background)}>
          <div className={twJoin('aspect-square rounded-lg shadow-md p-1', school.color)}>
            <school.Icon className='w-full' />
          </div>
          <p className='font-medium text-[0.6rem] md:text-xs pb-2 pt-1'>{displayName(school)}</p>
          {school.placement === 'campus' && showSelectCampus && (
            <PlacesDropdown
              onClose={() => {
                setShowSelectCampus(false);
                dispatch(setPerferredPlacement('campus'));
              }}
            />
          )}
        </button>
      ))}
    </div>
  );
}
