import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPacks, AppDispatch, PackStructure } from '@/types';
import { GetStructuredPacks } from '@/helpers/StructureData/StructureData';

interface PacksState {
  packs: IPacks[];
  structuredPacks: PackStructure[];
  isLoading: boolean;
  error: string;
}

const initialState: PacksState = {
  packs: [],
  structuredPacks: [],
  isLoading: false,
  error: '',
};

const packs = createSlice({
  name: 'packs',
  initialState,
  reducers: {
    setPacks(state, action: PayloadAction<IPacks[]>) {
      const p = action.payload;
      const structured = GetStructuredPacks(p);
      state.packs = action.payload;
      state.structuredPacks = structured;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const packsReducer = packs.reducer;

export const { setPacks, setIsLoading, setError } = packs.actions;

export function fetchPacks() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(setIsLoading(true));
      const { data } = await axios.get(import.meta.env.VITE_API_URL + 'subjects/full-packages');
      dispatch(setPacks(data));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}
