import { ExamArray } from '@/helpers/GetText/GetText';
import { getPackCourses } from '@/helpers/packHelpers';
import { AppState, PackStructure } from '@/types';
import { useSelector } from 'react-redux';
import {Icon} from "@/components/ui/Icon.tsx";

interface Props {
  pack: PackStructure;
  selectedPackVariant: PackStructure | null;
}
export function PackPopupCourses({ pack }: Props) {
  const { structuredCourses, courses } = useSelector((state: AppState) => state.courses);
  const { selectedCampus } = useSelector((state: AppState) => state.cart);

  const { tempC: packCourses } = getPackCourses({
    packObj: pack,
    allStructuredCourses: structuredCourses,
    courses,
    selectedCampus,
    type: "campus",
  });

  

  return (
    <div className='text-sm'>
      <h3 className='text-center font-bold'>Fag i pakken:</h3>
      <ul>
        {packCourses.map((course, i) => (
          <div key={course.course_code + '-pack-course-' + pack.alias + '-' + i} className='flex gap-2 justify-center'>
            <div className='flex gap-1'>
              {ExamArray(course.course_code).map((exam, y) => (
                <Icon
                  key={exam.type + '-pack-exam-' + course.course_code + '-' + i + '-' + y}
                  className='w-3 xl:w-4'
                  type={exam.type as 'oral-lab-exam' | 'oral-exam' | 'written-exam'}
                />
              ))}
            </div>
            <li>{course.title.replace(' online', '').replace(' live', '')}</li>
          </div>
        ))}
      </ul>
    </div>
  );
}
