import { twJoin } from 'tailwind-merge';
import { ISchool } from '../courses/courseSearch/CourseSearchSemesterIcons';
import { useState } from 'react';
import { Modal } from '../ui/Modal';
import { useSelector } from 'react-redux';
import { AppState } from '@/types';

interface Props {
  placement: ISchool;
}

export function PlacementInfoItem({ placement }: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  const { selectedCourses } = useSelector((state: AppState) => state.cart);
  const { semesters } = useSelector((state: AppState) => state.semester);

  const firstSemesterCourses = selectedCourses.filter(
    (course) =>
      (semesters[0].availableTimes.includes(course.selectedSemester) || course.selectedSemester === 'yearspring') &&
      course.type === placement.placement
  );

  const secondSemesterCourses = selectedCourses.filter(
    (course) =>
      (semesters[1].availableTimes.includes(course.selectedSemester) || course.selectedSemester === 'year') &&
      course.type === placement.placement
  );

  function generateText() {
    let text = '';

    if (placement.placement === 'online') {
      text = 'Start i dag!';
    } else {
      text = 'Fra 15. jan.';
    }

    if (placement.placement === 'online') {
      if (secondSemesterCourses.length > 0) {
        text = 'Fra 15. august.';
      }

      if (firstSemesterCourses.length > 0) {
        text = 'Start i dag.';
      }
    } else {
      if (secondSemesterCourses.length > 0) {
        text = 'Fra 15. august.';
      }

      if (firstSemesterCourses.length > 0) {
        text = 'Fra 15. jan.';
      }
    }

    return text;
  }

  return (
    <>
      <button
        onClick={() => {
          setModalOpen(true);
        }}
        className={twJoin(
          'rounded-lg text-center w-full flex-col items-center justify-center shadow-md p-1 text-xs',
          placement.color
        )}>
        <div className='flex flex-col items-center justify-center'>
          <placement.Icon className='w-8 h-8' />
          <p className='font-bold'>{placement.name}</p>
        </div>
        <p className='text-xs'>{generateText()}</p>
      </button>
      {modalOpen && (
        <Modal
          mode={placement.alias}
          onClose={() => {
            setModalOpen(false);
          }}>
          <div>
            <h2 className='font-bold text-center'>{placement.name}</h2>
            <div className='[&>h3]:font-bold [&>ul]:list-disc [&>ul]:pl-10'>{placement.info}</div>
          </div>
        </Modal>
      )}
    </>
  );
}
