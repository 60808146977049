import {ICourses, IPacks, ISubjects, PlacementType, SemesterType} from '@/types';
import {StructuredCourse, CourseVariants, PackStructure} from '@/types';
import {ShortPackNames} from '../Translators/ShortPackNames';

export const vocationalCourses = [
    {name: 'Vg1 Helse og oppvekstfag', courseCodes: ['HSF1006', 'HSF1005', 'HSF1007', 'HSF1008', 'HSF1009']},
    {name: 'Vg2 Helseservicefag', courseCodes: ['HES2005', 'HES2006', 'HES2007', 'HES2008']},
    {name: 'Vg3 Helsesekretær', courseCodes: ['KRO1006', 'HSE3005', 'HSE3006', 'HSE3007', 'HSE3008']},
    {name: 'Vg2 Helsearbeiderfag', courseCodes: ['HEA2005', 'HEA2006', 'HEA2007', 'HEA2008']},
    {name: 'Vg2 Barne- og ungdomsarbeider', courseCodes: ['BUA2005', 'BUA2006', 'BUA2007', 'BUA2008']},
    {name: 'Praksiskandidat - helsefag', courseCodes: ['HEA3103', 'BUA3103']},
    {name: 'Praksiskandidat Barne- og ungdomsarbeider', courseCodes: ['BUA3103']},
];

const mathCourses = [
    'REA3062',
    'REA3028',
    'REA3058',
    'REA3024',
    'MAT1151',
    'MAT1005',
    'MAT1019',
    'MAT1021',
    'REA3060',
    'REA3056',
    'MAT1023',
    'REA3056/ REA3058',
    'intro-MAT-online-s',
    'intro-MAT-online-r',
    'intro-MAT-online-p-p-y',
    'intro-MAT-online-t',
    'ulike y-koder',
    'intro-MAT1019/MAT1023',
    'intro-MAT1019/MAT1023/MAT1151/MAT1021',
    'intro-small-REA3056/REA3060',
    'MAT1019/MAT1023',
    'REA3056/REA3058',
    'pack-MAT-online-MAT1019/MAT1023',
    'pack-MAT-online-ulike y-koder/MAT1151AM3073',
];

const vocationalPackageNames = [
    'Vg1 Helse og oppvekstfag',
    'Vg2 Helseservicefag',
    'Vg3 Helsesekretær',
    'Vg2 Helsearbeiderfag',
    'Vg2 Barne- og ungdomsarbeider',
    'Praksiskandidat Barne- og ungdomsarbeider',
    'Praksiskandidat Helsefagarbeider',
    "VG1 Elektrofag",
    "VG2 Elektrofag"
];

const mathPackNames = ['Matte R1+R2', 'Matte S1+S2'];

const vocationalCoursesCodes = vocationalCourses.map((course) => course.courseCodes).flat();

export function GetStructuredPacks(packs: IPacks[]) {
    const structuredPacks: PackStructure[] = [] as PackStructure[];

    packs.forEach((pack) => {
        const name = pack.name;
        const nameStripped: string = ShortPackNames(name);
        const price: number = pack.price;

        const currentPack: PackStructure = {
            title: nameStripped,
            price: price,
            courses: pack.courses,
            placement: pack.placement,
            agreements: pack.agreements,
            lookup: pack.lookup,
            alias: pack.package_alias,
            vocationalPack: false,
        } as PackStructure;

        if (vocationalPackageNames.includes(currentPack.title)) {
            currentPack.vocationalPack = true;
        }

        if (mathPackNames.includes(currentPack.title)) {
            currentPack.mathPack = true;
        }

        structuredPacks.push(currentPack);
    });
    const agreements = [
        'sonpridra',
        'sonpriton',
        'sonpriosl',
        'sonpriber',
        'sonans',
        'sonpritrom',
        'sonprikri',
        'sondig',
        'sonprista',
        'sonpripor',
        'sonpriham',
        'sonprifre',
        'sonpribod',
        'sonprisan',
        'sonprilil',
        'sonpriski',
        'sonpritro',
    ];
    const placements = ['campus', 'live', 'online'];

    placements.forEach((p) => {
        const SonansPack: PackStructure = {
            title: 'Sonanspakken',
            price: 0,
            placement: p,
            agreements: agreements,
            alias: 'sonans_pack',
        } as PackStructure;

        structuredPacks.push(SonansPack);
    });

    return structuredPacks;
}

export function GetStructuredCourses(courses: ICourses[]) {
    const structuredCourses: StructuredCourse[] = [];
    const courseIds: string[] = [];
    const courseCodes: string[] = [];
    let subjects: ISubjects[] = [];

    // Gather all subjects from the courses
    courses.forEach((course) => {
        subjects = [...subjects, ...course.subjects];
    });

    // Extract unique course codes
    subjects.forEach((subject) => {
        const courseCode = subject.course_code;
        if (
            courseCodes.indexOf(courseCode) === -1 &&
            subject.dummy_parent_course_id === 'false' &&
            subject.has_new_course === 'false' &&
            courseCode !== '' &&
            courseCode !== null
        ) {
            courseCodes.push(courseCode);
        }
    });

    // Process each unique course code
    courseCodes.forEach((courseCode) => {
        const places: string[] = [];
        const times: string[] = [];
        let title = '';
        let variants: CourseVariants[] = [];
        let lab: CourseVariants[] = [];
        const upgrades: CourseVariants[] = [];
        const upgradePlaces: string[] = [];


        subjects.forEach((subject) => {
            if (
                (subject.course_code === courseCode || subject.course_code === `lab-${courseCode}`) &&
                subject.dummy_parent_course_id === 'false' &&
                subject.has_new_course === 'false'
            ) {
                const name = subject.title;
                const nameStripped: string = name
                    .replace(' online', '')
                    .replace(' live', '')
                    .replace('Grunnleggende matte - f', 'F')
                    .replace('Grunnleggende VGS-matte - f', 'F');
                title = nameStripped;
                if (times.indexOf(subject.time) === -1) {
                    times.push(subject.time);
                }
                subject.places.forEach((place: string) => {
                    if (places.indexOf(place) === -1) {
                        places.push(place);
                    }
                });
                if (nameStripped.includes('teskje') || nameStripped.includes('Bestått')) {
                    subject.places.forEach((place: string) => {
                        if (upgradePlaces.indexOf(place) === -1) {
                            upgradePlaces.push(place);
                        }
                    });
                }

                courseIds.push(subject.course_id);

                const currentCourse: CourseVariants = {
                    courseId: subject.course_id,
                    courseTitle: nameStripped,
                    courseCode: subject.course_code,
                    price: subject.price,
                    priceNetFullyear: subject.online_year_price,
                    priceNetHalfyear: subject.online_half_year_price,
                    time: subject.time as SemesterType,
                    places: subject.places,
                    yearHours: subject.year_hours,
                    type: subject.placement as Exclude<PlacementType, ''>, // Include placement
                };

                if (
                    subject.time === 'lab_practice_spring' ||
                    subject.time === 'lab_practice_autum' ||
                    subject.course_code.includes('lab-')
                ) {
                    lab.push(currentCourse);
                } else if (nameStripped.includes('teskje') || nameStripped.includes('Bestått')) {
                    upgrades.push(currentCourse);
                } else {
                    variants.push(currentCourse);
                }
            }
        });

        if (courseCode.includes('lab-')) {
            variants = lab;
            lab = [];
        }

        const courseTitle: string = title
            .replace(' med teskje', '')
            .replace(' Bestått', '')
            .replace(' forberedende til', 'forkurs')
            .replace('Forberedende til', 'Forkurs');

        const currentSub: StructuredCourse = {
            courseCode: courseCode,
            courseTitle: courseTitle,
            variants: variants,
            labCourses: lab,
            upgradeCourses: upgrades,
            upgradePlaces: upgradePlaces,
            times: times,
            places: places,
            category: '',
            mathCourse: false,
        };

        if (vocationalCoursesCodes.includes(courseCode)) {
            currentSub.vocationalSubject = true;

            vocationalCourses.forEach((vocationalCourse) => {
                if (vocationalCourse.courseCodes.includes(courseCode)) {
                    currentSub.category = vocationalCourse.name;
                }
            });
        }

        if (mathCourses.includes(courseCode)) {
            currentSub.mathCourse = true;
        }

        structuredCourses.push(currentSub);
    });

    return structuredCourses;
}
