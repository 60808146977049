import { ArrowDownRightIcon, ArrowRightIcon, MagnifyingGlassIcon } from '@heroicons/react/16/solid';

interface Props {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CourseSearchInput({ onChange }: Props) {
  return (
    <div className='border border-neutral-300 rounded-lg flex gap-0 lg:gap-1 text-neutral-300 h-[3rem] lg:h-[3.5rem] relative overflow-hidden'>
      <MagnifyingGlassIcon className='w-10 hidden lg:block' />
      <input
        onChange={onChange}
        placeholder='Søk etter fag og velg undervisningsform'
        className='border-0 visited:bg-black focus:ring-0 h-full w-full overflow-hidden focus:outline-none px-2 py-4 xl:text-lg text-black placeholder:text-[0.6rem] md:placeholder:text-base'
      />
      <ArrowRightIcon className='w-8 hidden lg:block' />
      <ArrowDownRightIcon className='w-5 block absolute right-0 bottom-0 lg:hidden ' />
    </div>
  );
}
