import { AppState } from '@/types';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CarouselItem } from './Carouseltem';
import useEmblaCarousel from 'embla-carousel-react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid';

/**
 * Carousel component that displays all the packs in the store
 */
export function Carousel() {
  const { selectedPack, filter } = useSelector((state: AppState) => state.cart);

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: 'start' });

  const structuredPacks = useSelector((state: AppState) => state.packs.structuredPacks);

  // useMemo is used to prevent the filteredPacks from being recalculated on every render
  const filteredPacks = useMemo(() => {
    // Separate the packs into three groups using filter
    const regularPacks = structuredPacks.filter((pack) => pack.placement === 'online' && !pack.mathPack);
    const mathPacks = structuredPacks.filter((pack) => pack.placement === 'online' && pack.mathPack);
    const vocationalPacks = structuredPacks.filter((pack) => pack.vocationalPack && !pack.mathPack);

    // Sort each group alphabetically by pack name
    regularPacks.sort((a, b) => a.title.localeCompare(b.title));
    mathPacks.sort((a, b) => a.title.localeCompare(b.title));
    vocationalPacks.sort((a, b) => a.title.localeCompare(b.title));

    // Concatenate the sorted groups
    return [...regularPacks, ...mathPacks, ...vocationalPacks];
  }, [structuredPacks]);

  // When the filter changes, scroll to the first pack that matches the filter
  useEffect(() => {
    if (!filter) return;

    if (emblaApi) {
      const firstIndexOfFilter = filteredPacks.findIndex((pack) => {
        if (filter === 'vocationalStudies') {
          return pack.vocationalPack;
        } else if (filter === 'generalStudies') {
          return !pack.vocationalPack && !pack.mathPack;
        } else if (filter === 'math') {
          return pack.mathPack;
        } else {
          return true;
        }
      });

      emblaApi.scrollTo(firstIndexOfFilter);
    }
  }, [filter, emblaApi, filteredPacks]);

  // When the selected pack changes, scroll to the selected pack
  useEffect(() => {
    if (!selectedPack) return;
    if (emblaApi) {
      const selectedPackIndex = filteredPacks.findIndex((pack) => pack.title === selectedPack?.title);
      emblaApi.scrollTo(selectedPackIndex);
    }
  }, [selectedPack, emblaApi, filteredPacks]);

  return (
    <div className='mb-4 flex flex-col gap-2'>
      <div className='flex items-center'>
        <button
          onClick={() => {
            if (emblaApi) {
              emblaApi.scrollPrev();
            }
          }}
          className='hidden lg:block text-neutral-500'>
          <ChevronLeftIcon className='h-12 w-12' />
        </button>
        <div className='embla overflow-hidden mx-auto w-[100%] xl:w-[80%] py-2' ref={emblaRef}>
          <div className='embla__container flex'>
            {filteredPacks.map((pack) => (
              <CarouselItem key={pack.alias} pack={pack} />
            ))}
          </div>
        </div>
        <button
          className='hidden lg:block text-neutral-500'
          onClick={() => {
            if (emblaApi) {
              emblaApi.scrollNext();
            }
          }}>
          <ChevronRightIcon className='h-12 w-12' />
        </button>
      </div>
      <div className='flex gap-1 justify-center items-center lg:hidden'>
        <div className='h-1 w-1 self-start shrink-0 bg-neutral-300 rounded-full'></div>
        <div className='h-1 w-1 self-start shrink-0 bg-neutral-500 rounded-full'></div>
        <div className='h-1 w-1 self-start shrink-0 bg-neutral-300 rounded-full'></div>
      </div>
    </div>
  );
}
