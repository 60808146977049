import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { twJoin } from 'tailwind-merge';

interface Props {
  readMore: boolean;
  onClick: () => void;
}

/**
 * Read more button that is used in various popups
 */
export function ReadMoreButton({ readMore, onClick }: Props) {
  return (
    <button onClick={onClick} className='self-start underline flex items-center'>
      {readMore ? 'Les mindre' : 'Les mer'}{' '}
      <ChevronDownIcon className={twJoin('w-4', readMore ? 'rotate-180' : '')} />
    </button>
  );
}
