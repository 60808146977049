import { useEffect, useMemo, useRef, useState } from 'react';
import { AppState } from '@/types';
import { useSelector } from 'react-redux';
import { CourseItem } from '../CourseItem';
import { CourseSearchInput } from '../CourseSearchInput';
import { CourseSearchSemesterIcons } from './CourseSearchSemesterIcons';
import { vocationalCourses } from '@/helpers/StructureData/StructureData';
import { Carousel } from '@/components/packs/Carousel';
import { CourseFilter } from './CourseFilter';

interface Props {
  expand: boolean;
}
/**
 * Component for searching and selecting courses
 */
export function CourseSearch({ expand }: Props) {
  const [search, setSearch] = useState('');
  const courseSearchRef = useRef<HTMLDivElement>(null);

  const { filter, manuallySelectedPack } = useSelector((state: AppState) => state.cart);
  const { structuredCourses } = useSelector((state: AppState) => state.courses);

  // Scroll to the top of the course search when the search or filter changes
  useEffect(() => {
    if (courseSearchRef.current) {
      courseSearchRef.current.scrollTo(0, 0);
    }
  }, [search, filter]);

  // useMemo is used to prevent the filteredCourses from being recalculated on every render
  const filteredCourses = useMemo(() => {
    const courses = structuredCourses.filter((course) => {
      const searchAdjusted = search
        .replace(/\bmatte\b/gi, 'matematikk')
        .replace(/\bmatt\b/gi, 'matematikk')
        .replace(/\bPY\b/gi, 'p-y')
        .replace(/\b1PY\b/gi, '1p-y')
        .replace(/\b1py\b/gi, '1p-y')
        .replace(/\b2PY\b/gi, '2p-y')
        .replace(/\b2py\b/gi, '2p-y')
        .replace(/\bit\b/gi, 'informasjonsteknologi')
        .replace(/\bPB\b/g, '')
        .replace(/\bgym\b/gi, 'kroppsøving')
        .toLocaleLowerCase();

      const searchRegex = new RegExp(searchAdjusted, 'i');

      return searchRegex.test(course.courseTitle.toLowerCase());
    });

    if (filter === 'math') {
      const mathCourses = courses.filter((course) => course.courseTitle.toLowerCase().includes('matematikk'));
      const regularCourses = courses.filter((course) => !course.courseTitle.toLowerCase().includes('matematikk'));
      return [...mathCourses, ...regularCourses];
    } else {
      return courses;
    }
  }, [search, structuredCourses, filter]);

  const generalStudiesCourses = filteredCourses.filter((course) => !course.vocationalSubject);
  const vocationalStudiesCourses = filteredCourses.filter((course) => course.vocationalSubject);

  const vocationalCategories = vocationalCourses.map((course) => course.name);

  // Show the course search if the course search is expanded and a pack is manually selected
  const expandAndManuallySelectedPack = expand === true && manuallySelectedPack;

  // Show the course search if the course search is expanded and no pack is manually selected
  const expandAndNoManuallySelectedPack = expand === true && !manuallySelectedPack;

  // Show the course search if the course search is not expanded and a pack is manually selected
  const notExpandAndManuallySelectedPack = expand === false && manuallySelectedPack;

  return (
    <div>
      {(notExpandAndManuallySelectedPack || expandAndManuallySelectedPack || expandAndNoManuallySelectedPack) && (
        <CourseFilter />
      )}
      {(notExpandAndManuallySelectedPack || expandAndManuallySelectedPack || expandAndNoManuallySelectedPack) && (
        <Carousel />
      )}
      {notExpandAndManuallySelectedPack && <p className='font-bold text-center'>Legg til fag</p>}
      {(expandAndManuallySelectedPack || expandAndNoManuallySelectedPack) && (
        <>
          <div>
            <section className='flex flex-col mx-auto w-[100%] xl:w-[80%] lg:pr-[5px]'>
              <div className='w-full grid grid-cols-[3fr,2fr] md:grid-cols-[3fr,1fr] gap-2'>
                <CourseSearchInput onChange={(e) => setSearch(e.target.value)} />
                <CourseSearchSemesterIcons />
              </div>
            </section>
          </div>
          <div
            ref={courseSearchRef}
            className='w-full h-[5.2rem] 2xl:h-[10.5rem] overflow-y-scroll scrollbar-hide lg:scrollbar-default relative'>
            <section id='course_search' className='flex flex-col h-full mx-auto w-[100%] xl:w-[80%]'>
              <ul className='flex flex-col'>
                {(filter === 'generalStudies' || filter === 'math' || filter === '') && (
                  <>
                    {generalStudiesCourses?.map((course) => (
                      <CourseItem onSelectCourse={() => {}} key={course.courseCode} course={course} />
                    ))}
                    {vocationalCategories.map((category) => (
                      <div key={category}>
                        {vocationalStudiesCourses.filter((c) => c.category === category).length > 0 && (
                          <p className='font-bold py-4'>{category}</p>
                        )}
                        {vocationalStudiesCourses
                          .filter((c) => c.category === category)
                          .map((course) => (
                            <CourseItem onSelectCourse={() => {}} key={course.courseCode} course={course} />
                          ))}
                      </div>
                    ))}
                  </>
                )}
                {filter === 'vocationalStudies' && (
                  <>
                    {vocationalStudiesCourses.length > 0 && vocationalCategories.map((category) => (
                      <div className='mb-6' key={category}>
                        {vocationalStudiesCourses.filter((c) => c.category === category).length > 0 && (
                          <p className='font-bold py-2'>{category}</p>
                        )}
                        {vocationalStudiesCourses
                          .filter((c) => c.category === category)
                          .map((course) => (
                            <CourseItem onSelectCourse={() => {}} key={course.courseCode} course={course} />
                          ))}
                      </div>
                    ))}
                    {generalStudiesCourses?.map((course) => (
                      <CourseItem onSelectCourse={() => {}} key={course.courseCode} course={course} />
                    ))}
                  </>
                )}
              </ul>
            </section>
          </div>
        </>
      )}
    </div>
  );
}
