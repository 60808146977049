import { Container } from './Container';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowButton } from './ArrowButton.tsx';
import { useState } from 'react';
import { Modal } from '@/components/ui/Modal';
import { Button } from './Button';
import Logo from '@/assets/logo.svg?react';

export function Header() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();


  return (
    <header className='bg-primary w-full h-[3rem] 2xl:h-[4rem] flex items-center'>
      <Container className='flex justify-between items-center'>
        <div className='w-full flex justify-start'>
          {location.pathname !== '/' && <ArrowButton arrowStyle='w-4 2xl:w-6' onClick={() => navigate(-1)} mode='nav-left' />}
        </div>
        <div className='w-full flex justify-center items-center'>
          <a href='https://sonans.no/'>
            <Logo className='w-24 2xl:w-32' />
          </a>
        </div>
        <div className='w-full flex justify-end'>
          <Button
            size='small'
            mode='outline'
            width='auto'
            onClick={() => {
              setModalOpen(true);
            }}>
            Hjelp
          </Button>
          {modalOpen && (
            <Modal id='help_popup'  mode='campus' onClose={() => setModalOpen(false)}>
              <div className='flex flex-col gap-4 items-center justify-center'>
                <h2 className='font-bold text-center'>Er du usikker på hva som er den beste løsningen for deg?</h2>
                <p>Fortsett sammen med en rådgiver</p>
                <a id='call_help_button' href='tel:+4791504070'>
                  <Button width='auto'>Ring 915 040 70</Button>
                </a>
              </div>
            </Modal>
          )}
        </div>
      </Container>
    </header>
  );
}
