import { ISchool } from '@/components/courses/courseSearch/CourseSearchSemesterIcons';
import { PlacesDropdown } from '@/components/places/PlacesDropdown';
import FormatPrice from '@/helpers/Functions/FormatPrice';
import { getNonPackPrice } from '@/helpers/Functions/GetNonPackPrice';
import { capitalize } from '@/helpers/courseHelpers';
import { AppState, IPlaces, PackStructure } from '@/types';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { twJoin, twMerge } from 'tailwind-merge';

interface Props {
  selectedPackVariant: PackStructure | null;
  school: ISchool;
  onSelect: (pack: PackStructure | null, campus?: IPlaces) => void;
  pack: PackStructure;
}

export function PackPlacementButton({ selectedPackVariant, school, pack, onSelect }: Props) {
  const [showCampusSelect, setShowCampusSelect] = useState(false);
  const [showWarning, setShowWarning] = useState('');
  const structuredCourses = useSelector((state: AppState) => state.courses.structuredCourses);
  const selectedCampus = useSelector((state: AppState) => state.cart.selectedCampus);
  const structuredPacks = useSelector((state: AppState) => state.packs.structuredPacks);

  const packVariants = structuredPacks.filter((p) => p.title === pack.title);

  const packVariant = packVariants.find((pack) => pack.placement === school.placement);

  const nonPackPrice = packVariant
    ? getNonPackPrice(packVariant, school.placement, structuredCourses, selectedCampus)
    : 0;

  const borderColor = {
    campus: 'border-primary',
    online: 'border-secondary',
    live: 'border-tertiary',
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showWarning) {
      timeout = setTimeout(() => {
        setShowWarning('');
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [showWarning]);

  return (
    <Fragment>
      <button
        onClick={() => {
          if (!packVariant) {
            setShowWarning(school.placement);
          }

          if (selectedPackVariant?.placement === school.placement) {
            onSelect(null);
            return;
          }

          if (!packVariant) return;

          if (packVariant.placement === 'campus' && !selectedCampus) {
            setShowCampusSelect(true);
          } else {
            onSelect(packVariant);
          }
        }}
        className={'flex gap-1 items-stretch text-xs relative'}
        key={school.placement}>
        <div
          className={twJoin(
            !packVariant ? 'bg-neutral-300 opacity-50' : school.color,
            'p-1 xl:p-2 rounded-md h-auto flex items-center'
          )}>
          <school.Icon className='w-6 xl:w-8' />
        </div>
        <div
          className={twMerge(
            'rounded-md border border-neutral-300 w-full lg:w-16 text-left p-1',
            selectedPackVariant && school.placement === selectedPackVariant.placement
              ? borderColor[selectedPackVariant.placement]
              : '',
            !packVariant ? 'opacity-50' : ''
          )}>
          {selectedCampus && school.placement === 'campus' ? (
            <p className='font-bold'>{selectedCampus.shortName}</p>
          ) : (
            <p className='font-bold'>{school.name}</p>
          )}
          {packVariant && nonPackPrice > packVariant.price && (
            <p className='line-through'>{FormatPrice(nonPackPrice)},-</p>
          )}
          {!packVariant && <p className='opacity-0'>0</p>}
          {!packVariant && <p className='opacity-0'>0</p>}
          {packVariant && <p>{FormatPrice(packVariant.price)},-</p>}
        </div>
        {showWarning && (
          <div className='top-0 z-50 left-[-20px] absolute bg-white shadow-md rounded-md p-1 w-32 text-xs border border-neutral-300'>
            <span>
              Ikke tilgjengelig{' '}
              {school.placement === 'campus' && selectedCampus
                ? `i ${selectedCampus?.shortName}`
                : `på ${capitalize(school.placement)}`}
              , velg Live.
            </span>
          </div>
        )}
      </button>
      {showCampusSelect && school.placement === 'campus' && (
        <PlacesDropdown
          ignorePageScroll
          className='w-full h-full flex items-center justify-center'
          overlay
          onSelect={(location) => {
            if (!packVariant) return;
            onSelect(packVariant, location);
          }}
          onClose={() => setShowCampusSelect(false)}
        />
      )}
    </Fragment>
  );
}
