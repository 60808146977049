import { AppState, PlacementType, SemesterType, StructuredCourse } from '@/types';
import { twJoin } from 'tailwind-merge';
import { useModal } from '../ui/useModal';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { AUTUMN_SEMESTERS, SPRING_SEMESTERS } from '@/utils/constants';
import { useEffect, useState } from 'react';
import { checkIfEarlyAccessCourse } from '@/helpers/GetText/libraries/GetCourseCodes';

interface Props {
  onSelect: (semester: SemesterType) => void;
  course: StructuredCourse;
  type: Exclude<PlacementType, ''>;
  onClose: () => void;
}

export function SelectSemesterOverlay({ course, type, onClose, onSelect }: Props) {
  const [selectedSemester, setSelectedSemester] = useState<SemesterType>('' as SemesterType);

  const [pageScroll, setPageScroll] = useState(0);

  const { selectedCampus, selectedCourses } = useSelector((state: AppState) => state.cart);

  const availableSemesters = course.variants
    .map((variant) => variant)
    .filter((variant) => {
      if (type === 'campus' && selectedCampus) {
        return variant.type === type && variant.places.includes(selectedCampus.alias);
      }

      return variant.type === type;
    })
    .map((variant) => variant.time);

  const semesterData = [
    { alias: 'spring', name: 'Vår', availableTimes: [...SPRING_SEMESTERS] },
    { alias: 'autumn', name: 'Høst', availableTimes: [...AUTUMN_SEMESTERS] },
    { alias: 'year', name: 'Helår', availableTimes: ['year'] },
  ];

  useEffect(() => {
    if (selectedCourses.length > 0) {
      const courseInCart = selectedCourses.find((c) => c.course.courseCode === course.courseCode);
      if (courseInCart) {
        setSelectedSemester(courseInCart.selectedSemester);
      }
    }
  }, [selectedCourses, course.courseCode]);

  const { modalContentRef } = useModal({ onClose });

  useEffect(() => {
    setPageScroll(window.scrollY);
  }, []);

  const borderColors = {
    campus: 'border-primary',
    live: 'border-tertiary',
    online: 'border-secondary',
  };

  function disableEarlyAccess(semester: string) {
    const isEarlyAccess = checkIfEarlyAccessCourse(course.courseCode);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (currentYear === 2024 && isEarlyAccess && type === 'online' && (semester === 'autumn' || semester === 'year')) {
      return true;
    } else {
      return false;
    }
  }

  function disable(semester: (typeof semesterData)[number]) {
    if (type === 'online') {
      return disableEarlyAccess(semester.alias);
    } else {
      return !availableSemesters.some((s) => semester.availableTimes.includes(s));
    }
  }

  return createPortal(
    <div style={{ top: pageScroll > 0 ? `-${pageScroll - 42}px` : '42px' }} className='absolute'>
      <div
        onClick={() => {
          onClose();
        }}
        className='fixed top-0 w-[100vw] h-[100svh] left-0 z-50 overflow-hidden bg-black/20'></div>
      <ul
        ref={modalContentRef}
        className={twJoin(
          'fixed outline-none text-xs w-24 2xl:text-base animate-reveal text-left z-[51] bg-white p-2 rounded-lg border-2 flex flex-col gap-2 h-auto',
          borderColors[type]
        )}>
        {semesterData.map((semester) => (
          <div key={semester.alias} className='flex items-center gap-1'>
            <input
              onChange={() => {
                onSelect(semester.alias as SemesterType);
              }}
              name='semester-select'
              id={semester.alias}
              checked={selectedSemester === semester.alias}
              disabled={disable(semester)}
              className='disabled:opacity-50 rounded-full checked:bg-black focus:ring-black focus:bg-black visible:hidden "cursor-pointer'
              type='checkbox'
            />
            <label
              htmlFor={semester.alias}
              className={twJoin(disable(semester) ? 'opacity-50 line-through cursor-default' : '', 'cursor-pointer')}>
              {semester.name}
            </label>
          </div>
        ))}
      </ul>
    </div>,
    document.querySelector('#course-search-semester-icons') as HTMLElement
  );
}
