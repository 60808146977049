import FormatPrice from '@/helpers/Functions/FormatPrice';
import { AppState, PackStructure } from '@/types';
import { getNonPackPrice } from '@/helpers/Functions/GetNonPackPrice';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import { PackPopup } from './popup/PackPopup';

interface Props {
  pack: PackStructure;
}

export function CarouselItem({ pack }: Props) {
  const [showPopup, setShowPopup] = useState(false);

  const { structuredCourses } = useSelector((state: AppState) => state.courses);
  const { selectedPack, filter, selectedCampus } = useSelector((state: AppState) => state.cart);

  const packPrice = selectedPack && selectedPack.title === pack.title ? selectedPack.price : pack.price;

  const nonPackPrice = selectedPack && selectedPack.title === pack.title ? getNonPackPrice(selectedPack, selectedPack.placement, structuredCourses, selectedCampus) : getNonPackPrice(pack, pack.placement, structuredCourses, selectedCampus);

  const backgroundColor = {
    campus: 'bg-primary',
    online: 'bg-secondary',
    live: 'bg-tertiary',
  };


  return (
    <>
      <button
        data-testid={`pack-item-${pack.alias}`}
        onClick={() => {
          setShowPopup(true);
        }}
        className={twMerge(
          'border mr-2 text-center border-neutral-300 rounded-lg shadow-md h-[4rem] xl:h-[5rem] w-[12rem] self-start shrink-0 flex items-center justify-between flex-col p-1 snap-start overflow-hidden',
          selectedPack?.title === pack.title ? backgroundColor[selectedPack.placement] : 'bg-white',
          filter === 'vocationalStudies' &&
            pack.vocationalPack &&
            selectedPack?.title !== pack.title &&
            pack.alias !== 'sonans_pack'
            ? 'border-primary'
            : '',
          filter === 'generalStudies' &&
            !pack.vocationalPack &&
            !pack.mathPack &&
            selectedPack?.title !== pack.title &&
            pack.alias !== 'sonans_pack'
            ? 'border-primary'
            : '',
          filter === 'math' && pack.mathPack && selectedPack?.title !== pack.title && pack.alias !== 'sonans_pack'
            ? 'border-primary'
            : ''
        )}>
        <h3 className='font-medium text-ellipsis'>{pack.title}</h3>
        {pack.alias !== 'sonans_pack' && (
          <div>
            {nonPackPrice > packPrice && <p className='line-through'>{FormatPrice(nonPackPrice)},-</p>}
            <p>{FormatPrice(packPrice)},-</p>
          </div>
        )}
        {pack.alias === 'sonans_pack' && (
          <div className='text-xs lg:text-sm'>
            <p>Velg 6 fag</p>
            <p>– få 15% rabatt</p>
          </div>
        )}
      </button>
      {showPopup && <PackPopup onClose={() => setShowPopup(false)} pack={pack} />}
    </>
  );
}
