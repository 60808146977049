import { useSelector } from 'react-redux';
import { Container } from '../../ui/Container';
import { AppState } from '@/types';
import FormatPrice from '@/helpers/Functions/FormatPrice';
import { Link } from 'react-router-dom';
import { Button } from '../../ui/Button';
import { useState } from 'react';
import { Modal } from '../../ui/Modal';
import { PackPopupInfo } from '../../packs/popup/PackPopupInfo';

export function Price() {
  const [showLk, setShowLk] = useState(false);
  const [showPackPopup, setShowPackPopup] = useState(false);

  const { total, totalBefore, packCoverage } = useSelector((state: AppState) => state.cart);

  return (
    <div>
      {total > 0 && (
        <div className='flex gap-0 flex-col justify-center items-center'>
          {totalBefore !== total && <p className='text-sm line-through text-text-grey'>{FormatPrice(totalBefore)},-</p>}
          <p className='font-semibold text-base xl:text-lg'>Totalpris {FormatPrice(total)},-</p>
          {packCoverage && (
            <button className='underline' onClick={() => setShowPackPopup(true)}>
              Du får rabatt med {packCoverage.packageName}
            </button>
          )}
          {showPackPopup && packCoverage && (
            <Modal mode='campus' onClose={() => setShowPackPopup(false)}>
              <div className='flex flex-col gap-2 items-center'>
                <h2 className='text-base lg:text-xl font-semibold'>{packCoverage.package.title}</h2>
                <PackPopupInfo pack={packCoverage.package} />
              </div>
            </Modal>
          )}
        </div>
      )}
      <div className='fixed bottom-0 2xl:relative z-20 left-0 py-2 w-full bg-white'>
        <Container className='flex justify-center items-center flex-col gap-1 text-center'>
          <button onClick={() => setShowLk(true)} className='underline'>
            Lån og stipend
          </button>
          <Link to='/paamelding'>
            <Button width='auto' mode='secondary'>
              Neste
            </Button>
          </Link>
        </Container>
        {showLk && (
          <Modal mode='campus' onClose={() => setShowLk(false)}>
            <div className='w-full flex flex-col gap-4 items-center text-center text-sm'>
              <h2 className='text-base lg:text-xl font-semibold'>Du kan søke lån og stipend fra lånekassen.</h2>
              <p>
                Du kan få inntil 151.690,- i basislån per undervisningsår. Inntil 40 % av basislånet kan gjøres om til
                stipend.
              </p>
              <p>Du kan få ekstralån til skolepenger på inntil kr 77.192,- per år.</p>
              <p>Du får støtte ut ifra studiebelastningen din.</p>
              <p>Her kan du beregne hvor mye støtte du kan få Lånekassens støttekalkulator.</p>
              <a target='_blank' href='https://lanekassen.no/nb-NO/stipend-og-lan/norge/universitet-og-hogskole/'>
                <Button>Lånekassen</Button>
              </a>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}
