import { AutumnSemester, ISemester, SemesterType, SpringSemester } from '@/types';
import { AUTUMN_SEMESTERS, SPRING_SEMESTERS } from '@/utils/constants';

export const setSemester = (semester: { semesterAmount: number; firstSemester: string; year: number }): ISemester[] => {
  const { semesterAmount, firstSemester, year } = semester;
  const semesters: ISemester[] = [];

  const isAutumnFirst = firstSemester === 'autumn';
  
  const getSemesterName = (semesterIndex: number): string => 
    (semesterIndex % 2 === 0 ? (isAutumnFirst ? 'Høst' : 'Vår') : (isAutumnFirst ? 'Vår' : 'Høst'));
  
  const getExamString = (semesterName: string): string =>
    semesterName === 'Vår' ? 'Eksamen apr.-jun.' : 'Eksamen nov.-des.';

  for (let i = 0; i < semesterAmount; i++) {
    const semesterName = getSemesterName(i);
    const examString = getExamString(semesterName);

    const currentYear = year + Math.floor((i + (isAutumnFirst ? 1 : 0)) / 2);
    const semesterTitle = `${semesterName} ${currentYear}`;

    const semesterAlias: SemesterType = semesterName === 'Høst' ? 'autumn' : 'spring';
    const availableTimes: AutumnSemester[] | SpringSemester[] =
      semesterAlias === 'autumn' ? [...AUTUMN_SEMESTERS] : [...SPRING_SEMESTERS];

    semesters.push({
      semesterTitle,
      examString,
      semesterAlias,
      availableTimes,
    });
  }

  return semesters;
};
