import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../types';
import { useEffect } from 'react';
import { PageTitle } from '../components/ui/PageTitle';
import { fetchPlaces } from '@/reducers/places';
import { getSemesters } from '@/reducers/semesters';
import { PracticalInfo } from '@/components/info/PracticalInfo';
import { CourseSearchContainer } from '@/components/courses/CourseSearchContainer';
import { fetchCourses } from '@/reducers/courses';
import { fetchPacks } from '@/reducers/packs';
import { Price } from '@/components/courses/semesterCourseContainer/Price';
import { SemesterCourseContainer } from '@/components/courses/semesterCourseContainer/SemesterCourseContainer';

/**
 * Courses page where the user can search for courses and add them to the cart
 */
export function Courses() {
  const dispatch = useDispatch<AppDispatch>();

  const { selectedCourses } = useSelector((state: AppState) => state.cart);
  const { structuredCourses } = useSelector((state: AppState) => state.courses);
  const { structuredPacks } = useSelector((state: AppState) => state.packs);
  const { places } = useSelector((state: AppState) => state.places);

  useEffect(() => {
    dispatch(getSemesters());

    // Fetch courses, packs and places if they are not already in the store
    if (structuredCourses.length === 0) {
      dispatch(fetchCourses());
    }

    if (structuredPacks.length === 0) {
      dispatch(fetchPacks());
    }

    if (places.length === 0) {
      dispatch(fetchPlaces());
    }
    
  }, [dispatch, places, structuredCourses, structuredPacks]);

  return (
    <div className='flex flex-col gap-2 2xl:gap-4 pb-[12rem]'>
      <PageTitle>Sett opp din plan</PageTitle>
      <CourseSearchContainer />
      <SemesterCourseContainer />
      <PracticalInfo />
      {selectedCourses.length > 0 && <Price />}
    </div>
  );
}
