import { AppDispatch, AppState, FilterType } from '@/types';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '@/reducers/cart';
import { twJoin } from 'tailwind-merge';

interface FilterOptions {
  name: string;
  value: FilterType;
}


/**
 * Filter for the course search and packs
 */
export function CourseFilter() {
  const filters: FilterOptions[] = [
    {
      name: 'Studiespes',
      value: 'generalStudies',
    },
    {
      name: 'Yrkesfag',
      value: 'vocationalStudies',
    },
    {
      name: 'Matte',
      value: 'math',
    },
  ];

  const dispatch = useDispatch<AppDispatch>();

  const selectedFilter = useSelector((state: AppState) => state.cart.filter);

  return (
    <div className='flex w-[100%] xl:w-[80%] mx-auto gap-1 items-center'>
      <svg className='w-4 flex-shrink-0' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8 18V12H10V14H18V16H10V18H8ZM0 16V14H6V16H0ZM4 12V10H0V8H4V6H6V12H4ZM8 10V8H18V10H8ZM12 6V0H14V2H18V4H14V6H12ZM0 4V2H10V4H0Z'
          fill='#1C1B1F'
        />
      </svg>
      {filters.map((filter) => (
        <button
        key={filter.value}
          className={twJoin(
            selectedFilter === filter.value
              ? 'bg-primary text-black border border-primary'
              : 'bg-white border border-black',
            'rounded-full py-0 2xl:py-1 w-[5rem] md:w-[6rem] 2xl:w-[8rem] flex items-center justify-center'
          )}
          onClick={() => {
            if (selectedFilter === filter.value) {
              dispatch(setFilter(''));
            } else {
              dispatch(setFilter(filter.value));
            }
          }}>
          {filter.name}
        </button>
      ))}
    </div>
  );
}
