import { twMerge } from "tailwind-merge";
import {Icon} from "@/components/ui/Icon.tsx";

interface Props {
  className?: string;
}

export function SecondSemesterYearInfo({ className }: Props) {
  return (
    <div className={twMerge('bg-primary/30 border w-full text-xs justify-center border-primary rounded-lg text-center items-center flex flex-col p-2', className)}>
      <p className="font-bold">Faget avsluttes våren 2026</p>
      <p className="flex gap-1">
        <Icon className='w-4' type='arrow-thin-right' /> DEL 1
      </p>
    </div>
  );
}
