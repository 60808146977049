import { configureStore } from '@reduxjs/toolkit';
import { packsReducer } from './packs';
import { coursesReducer } from './courses';
import { examsReducer } from './exams';
import { placesReducer } from './places';
import { cartSlice } from './cart';
import { semesterSlice } from './semesters';
import { loadState, localStorageMiddleware } from './middleware/localStorage';

const preloadedState = loadState();

export const store = configureStore({
  reducer: {
    packs: packsReducer,
    courses: coursesReducer,
    exams: examsReducer,
    places: placesReducer,
    [cartSlice.name]: cartSlice.reducer,
    [semesterSlice.name]: semesterSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware),
  preloadedState,
});
