import { PlacementType } from '@/types';
import { twJoin } from 'tailwind-merge';

interface Props {
  placement: PlacementType;
}

const infoColor = {
  campus: 'bg-primary',
  online: 'bg-secondary',
  live: 'bg-tertiary',
  '': 'bg-secondary',
};

export function CourseInfoTag({ placement }: Props) {
  return (
    <div className={twJoin('w-[1rem] h-full flex items-center justify-center', infoColor[placement])}>
      <p className='rotate-[-90deg] uppercase text-black/40 text-xs'>Info</p>
    </div>
  );
}
