import { ChevronDownIcon } from '@heroicons/react/16/solid';

interface Props {
  onClick: () => void;
  title: React.ReactNode;
  description: string;
  icon: React.ReactNode;
}

export function VerificationInfo({ onClick, title, description, icon }: Props) {

  return (
    <button
      onClick={onClick}
      className='border-2 border-primary p-4 rounded-lg w-full flex flex-col gap-2 items-center'>
      {icon}
      <p className='text-xs lg:text-base font-semibold break-words hyphens-auto sm:break-normal'>{title}</p>
      <p>{description}</p>
      <div className='hover:cursor-pointer mt-auto'>
        <ChevronDownIcon className='w-6' type='arrow-full-left' />
      </div>
    </button>
  );
}
