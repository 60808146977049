import { AppState } from '@/types';
import { Middleware } from '@reduxjs/toolkit';

// Middleware to save the cart state to localStorage on changes
export const localStorageMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState() as AppState;

  const stateToSave = {
    updatedAt: new Date().toISOString(),
    state,
  };

  localStorage.setItem('state', JSON.stringify(stateToSave));

  return result;
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }

    const parsedState = JSON.parse(serializedState);
    const savedTime = new Date(parsedState.updatedAt);
    const currentTime = new Date();

    // cache for state in localStorage. Currently set to 1 hour
    const hoursInMilliseconds = 60 * 60 * 1000;

    if (currentTime.getTime() - savedTime.getTime() > hoursInMilliseconds) {
      return undefined;
    }

    return parsedState.state;
  } catch (error) {
    return undefined;
  }
};
