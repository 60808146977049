import { AppDispatch, AppState, PackStructure } from '@/types';
import { Modal } from '../../ui/Modal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { schools } from '../../courses/courseSearch/CourseSearchSemesterIcons';
import { Button } from '../../ui/Button';
import { addPackToCart, setManuallySelectedPack, setSelectedPack } from '@/reducers/cart';
import { PackPopupInfo } from './PackPopupInfo';
import { PackPopupCourses } from './PackPopupCourses';
import { viewPackGTAG } from '@/helpers/gtag/viewPack';
import { PackExamInfo } from './PackExamInfo';
import { PackPlacementButton } from './PackPlacementButton';

interface Props {
  pack: PackStructure;
  onClose: () => void;
}

export function PackPopup({ pack, onClose }: Props) {
  const [selectedPackVariant, setSelectedPackVariant] = useState<PackStructure | null>(null);
  const { structuredCourses, courses } = useSelector((state: AppState) => state.courses);
  const { selectedCampus, selectedCourses, selectedPack } = useSelector((state: AppState) => state.cart);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (selectedPack && selectedPack.title === pack.title) {
      setSelectedPackVariant(selectedPack);
    }
  }, [selectedPack, pack.title]);

  useEffect(() => {
    viewPackGTAG({ pack });
  }, [pack]);

  return (
    <Modal id='pack_popup' onClose={onClose} mode='campus'>
      <div className='flex flex-col gap-2 overflow-scroll max-h-[75svh]'>
        <h2 className='text-center font-bold'>{pack.title}</h2>
        <PackPopupInfo pack={pack} />
        {pack.alias !== 'sonans_pack' && <PackPopupCourses pack={pack} selectedPackVariant={selectedPackVariant} />}
        {pack.alias !== 'sonans_pack' && (
          <div className='flex flex-col gap-2'>
            <div className='flex flex-row justify-center items-center gap-2'>
              {schools.map((school) => (
                <PackPlacementButton
                  key={school.placement}
                  school={school}
                  pack={pack}
                  selectedPackVariant={selectedPackVariant}
                  onSelect={(pack, campus) => {
                    if (selectedCourses.length > 0) {
                      // If courses are selected, temporarily store the pack and show the user the option to replace the selected courses with the pack or add the pack to the cart
                      setSelectedPackVariant(pack);
                    } else {
                      // If no courses are selected, add the pack to the cart directly
                      if (pack) {
                        dispatch(setSelectedPack(pack));
                        dispatch(setManuallySelectedPack(true));
                        dispatch(
                          addPackToCart({
                            replace: false,
                            allStructuredCourses: structuredCourses,
                            pack: pack,
                            courses: courses,
                            // If campus is selected for the first time, use that, otherwise use the selectedCampus from the cart
                            selectedCampus: campus || selectedCampus,
                            selectedCourses,
                          })
                        );
                        onClose();
                      }
                    }
                  }}
                />
              ))}
            </div>
            {selectedPackVariant && selectedCourses.length > 0 && (
              <div className='flex flex-col lg:flex-row gap-2 justify-center w-full'>
                <Button
                  onClick={() => {
                    dispatch(setSelectedPack(selectedPackVariant));
                    dispatch(setManuallySelectedPack(true));
                    dispatch(
                      addPackToCart({
                        replace: true,
                        allStructuredCourses: structuredCourses,
                        pack: selectedPackVariant,
                        courses: courses,
                        selectedCampus,
                        selectedCourses,
                      })
                    );
                    onClose();
                  }}
                  mode='secondary'
                  width='full'
                  size='medium'>
                  Erstatt valg med pakken
                </Button>
                <Button
                  onClick={() => {
                    dispatch(setSelectedPack(selectedPackVariant));
                    dispatch(setManuallySelectedPack(true));
                    dispatch(
                      addPackToCart({
                        replace: false,
                        allStructuredCourses: structuredCourses,
                        pack: selectedPackVariant,
                        courses: courses,
                        selectedCampus,
                        selectedCourses,
                      })
                    );
                    onClose();
                  }}
                  mode='primary'
                  width='full'
                  size='medium'>
                  Legg til pakken
                </Button>
              </div>
            )}
            <PackExamInfo />
          </div>
        )}
      </div>
    </Modal>
  );
}
