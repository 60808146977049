import { selectedCourses } from '@/types';
import dataLayer from '@/utils/dataLayer';

export function addCourseGTAG({ selectedCourse }: { selectedCourse: selectedCourses }) {
  const addEvent = {
    event: 'add_to_cart',
    currency: 'NOK',
    value: selectedCourse.selectedPrice,
    items: [
      {
        item_name: selectedCourse.selectedCourse.courseTitle,
        discount: 0,
        item_type: selectedCourse.selectedCourse.time,
        item_brand: 'Sonans',
        item_category: 'fag',
        item_id: selectedCourse.selectedCourse.courseId + selectedCourse.selectedCourse.time,
        price: selectedCourse.selectedCourse.price,
        quantity: 1,
      },
    ],
  };
  dataLayer.push(addEvent);
}
