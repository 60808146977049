import {Icon} from "@/components/ui/Icon.tsx";

export function PackExamInfo() {
  return (
    <div className='flex flex-col gap-1 text-xs text-center'>
      <p className='font-semibold'>Type eksamen:</p>
      <div className='flex justify-center gap-4'>
        <div className='flex gap-2 items-center'>
          <Icon className='w-4' type='written-exam' />
          <p>Skriftlig</p>
        </div>
        <div className='flex gap-2 items-center'>
          <Icon className='w-4' type='oral-exam' />
          <p>Muntlig</p>
        </div>
        <div className='flex gap-2 items-center'>
          <Icon className='w-4 flex-shrink-0' type='oral-lab-exam' />
          <p>Muntlig-praktisk</p>
        </div>
      </div>
    </div>
  );
}
