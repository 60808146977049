import { IconTypes, StructuredCourse } from "@/types";
import { CheckIfFullyearCourse } from "../Functions/CheckSemester";

export function getCourseType(location: string) {
    let type: IconTypes = 'campus';
    if (location === 'sondig') {
        type = 'live'
    } else if (location === 'sonans') {
        type = 'online'
    } else {
        type = 'campus'
    }

    return type;
}

export function getSelectedCourse(course: StructuredCourse, location: string, semester: string) {
    const isFullyearCourse = CheckIfFullyearCourse(course.courseCode);
    let sem = isFullyearCourse ? 'year' : semester;
    let otherSem = sem === 'autumn' ? 'spring' : 'autumn'
    if (course.courseCode.includes('lab-')) {
        sem = sem === 'autumn' ? 'lab_practice_autum' : 'lab_practice_spring'
        otherSem = sem === 'lab_practice_autum' ? 'lab_practice_spring' : 'lab_practice_autum'
    } else if (course.courseCode.includes('intro-')) {
        sem = sem === 'autumn' ? 'introautumn' : 'introspring'
        otherSem = sem === 'introautumn' ? 'introspring' : 'introautumn'
        if(course.times.indexOf(sem) === -1) {
            sem = sem === 'introautumn' ? 'summer_school' : 'newyear_school'
        }
        if(course.times.indexOf(otherSem) === -1) {
            otherSem = otherSem === 'introautumn' ? 'summer_school' : 'newyear_school'
        }
    }
    
    sem = location === 'sonans' ? 'netbased' : sem;

    let selCourse = course.variants.filter((variant) => variant.places.indexOf(location) !== -1 && variant.time === sem)
    if (selCourse.length === 0) {
        selCourse = course.variants.filter((variant) => variant.places.indexOf(location) !== -1 && variant.time === otherSem)
    }
    if (selCourse.length === 0) {
        selCourse = course.variants.filter((variant) => variant.places.indexOf(location) !== -1 && variant.time === 'year')
    }

    return selCourse[0]
}

export function getChosenSemVariants(semester: string) {
    let shownSemesters = ['autumn', 'summer_school', 'introautumn', 'lab_practice_autum', 'netbased']
    if (semester === 'spring') {
        shownSemesters = ['spring', 'newyear_school', 'introspring', 'lab_practice_spring', 'netbased']
    }

    return shownSemesters
}