interface Props {
  content: string;
  word: string;
}

export function Underline({ content, word }: Props) {
  const output = content.split(' ').map((item, index) => {
    if (item === word) {
      return <u key={index}>{item}</u>;
    } else {
      return <span key={index}>{item}</span>;
    }
  });

  return <>{output}</>;
}
