import { checkIfEarlyAccessCourse } from '@/helpers/GetText/libraries/GetCourseCodes';
import { CourseVariants,selectedCourses } from '@/types';
import { useEffect, useState } from 'react';

interface Props {
  course: selectedCourses;
}

export function useCoursePopup({ course }: Props) {
  const [studyLoad, setStudyLoad] = useState<number>(0);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showTop, setShowTop] = useState<boolean>(true);
  const [hasAutumn, setHasAutumn] = useState<boolean>(false);
  const [hasSpring, setHasSpring] = useState<boolean>(false);
  const [hasYear, setHasYear] = useState<boolean>(false);
  const [hasSpringYear, setHasSpringYear] = useState<boolean>(false);
  const [hasLabAutumn, setHasLabAutumn] = useState<boolean>(false);
  const [hasLabSpring, setHasLabSpring] = useState<boolean>(false);
  const [selectedSem, setSelectedSem] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [isPackCourse, setIsPackCourse] = useState<boolean>(false);
  const [autumnCourse, setAutumCourse] = useState<CourseVariants>({} as CourseVariants);
  const [springCourse, setSpringCourse] = useState<CourseVariants>({} as CourseVariants);
  const [yearCourse, setYearCourse] = useState<CourseVariants>({} as CourseVariants);
  const [springYearCourse, setSpringYearCourse] = useState<CourseVariants>({} as CourseVariants);
  const [autumnLabCourse, setAutumnLabCourse] = useState<CourseVariants>({} as CourseVariants);
  const [springLabCourse, setSpringLabCourse] = useState<CourseVariants>({} as CourseVariants);
  const [isUpgradeCourse, setIsUpgradeCourse] = useState<boolean>(false);
  const [upgradeCourse, setUpgradeCourse] = useState<CourseVariants>({} as CourseVariants);

  useEffect(() => {
    const courseCode = course.courseCode;
    const type = course.type;

    if (courseCode.includes('intro-') || courseCode.includes('lab-')) {
      setShowTop(false);
      setShowInfo(true);
    }

    if (type !== 'online') {
      const autumn = course.variants.filter((v) => v.time === 'autumn');
      const spring = course.variants.filter((v) => v.time === 'spring');
      const year = course.variants.filter((v) => v.time === 'year');
      const springYear = course.variants.filter((v) => v.time === 'yearspring');
      const labAutumn = course.variants.filter((v) => v.time === 'lab_practice_autum');
      const labSpring = course.variants.filter((v) => v.time === 'lab_practice_spring');

      if (autumn.length > 0) {
        setHasAutumn(true);
        setAutumCourse(autumn[0]);
      }
      if (spring.length > 0) {
        setHasSpring(true);
        setSpringCourse(spring[0]);
      }
      if (year.length > 0) {
        setHasYear(true);
        setYearCourse(year[0]);
      }
      if (springYear.length > 0) {
        setHasSpringYear(true);
        setSpringYearCourse(springYear[0]);
      }
      if (labAutumn.length > 0) {
        setHasLabAutumn(true);
        setAutumnLabCourse(labAutumn[0]);
      }
      if (labSpring.length > 0) {
        setHasLabSpring(true);
        setSpringLabCourse(labSpring[0]);
      }
    } else {

      setHasAutumn(true);
      setAutumCourse(course.selectedCourse);
      setHasSpring(true);
      setSpringCourse(course.selectedCourse);
      setHasYear(true);
      setYearCourse(course.selectedCourse);
      setHasSpringYear(true);
      setSpringYearCourse(course.selectedCourse);
    }

    setSelectedSem(course.selectedSemester);
    setSelectedTime(course.selectedTime);
    setIsPackCourse(course.packCourse);
    
    if (
      course.selectedCourse.courseTitle.includes(' med teskje') ||
      course.selectedCourse.courseTitle.includes(' bestått')
    ) {
      setIsUpgradeCourse(true);
      setUpgradeCourse(course.selectedCourse);
    }

    // Temp logic to force certain courses to be spring only
    const isEarlyAccess = checkIfEarlyAccessCourse(course.courseCode);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (currentYear === 2024 && isEarlyAccess && type === 'online') {
      setHasAutumn(false);
      setHasYear(false);
      setHasSpringYear(false);
    }
    // End of temp logic
  }, [course]);

  return {
    studyLoad,
    setStudyLoad,
    showInfo,
    showTop,
    hasAutumn,
    hasSpring,
    hasYear,
    hasSpringYear,
    hasLabAutumn,
    hasLabSpring,
    selectedSem,
    selectedTime,
    isPackCourse,
    autumnCourse,
    springCourse,
    yearCourse,
    springYearCourse,
    autumnLabCourse,
    springLabCourse,
    isUpgradeCourse,
    upgradeCourse,
  };
}
