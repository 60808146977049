import { BookCounseling } from '@/components/confirm/BookCounseling';
import { PageTitle } from '@/components/ui/PageTitle';
import { PackLine } from '@/components/verification/PackLine';
import { VerificationLine } from '@/components/verification/VerificationLine';
import FormatPrice from '@/helpers/Functions/FormatPrice';
import { beginCheckoutGTAG } from '@/helpers/gtag/checkout';
import { AppState } from '@/types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * Confirm page that displays after the user has placed an order
 */
export function ConfirmPage() {
  const { total, totalBefore, sonansPack, selectedCourses, selectedPack } = useSelector(
    (state: AppState) => state.cart
  );

  // Filter courses that are part of a pack but not the 'sonanspack'
  const packCourses = selectedCourses.filter((c) => c.packCourse === true && c.pack !== 'sonanspack');

  const courses = selectedCourses.filter(
    (c) => c.packCourse === false || (c.packCourse == true && c.pack === 'sonanspack')
  );

  // usEffect to fire the GTAG event only once
  useEffect(() => {
    const saleCompleted = JSON.parse(localStorage.getItem('completed_sale') || 'false');

    if (!saleCompleted) {
      beginCheckoutGTAG(courses, selectedPack ? [selectedPack] : [], 'purchase', total);

      // Set the completed_sale to true to prevent the GTAG from firing again
      localStorage.setItem('completed_sale', 'true');
    }
  }, [courses, selectedPack, total]);

  return (
    <div>
      <PageTitle>Takk for din påmelding!</PageTitle>
      <button
        onClick={() => {
          window.scrollTo(0, document.body.scrollHeight);
        }}
        className='text-center pt-4 w-full 2xl:hidden'>
        Ønsker du å snakke med en rådgiver? <span className='font-bold underline'>Klikk her</span>
      </button>
      <div className='flex flex-col divide-y-[0.05rem] mt-8'>
        {selectedPack && selectedPack.alias !== 'sonanspack' && (
          <PackLine key={`${selectedPack.alias}-pack`} pack={selectedPack} />
        )}
        {packCourses.map((c) => (
          <VerificationLine key={c.courseCode + '-verification-line-pc'} course={c} />
        ))}
        {packCourses.length > 0 && courses.length > 0 && (
          <div className=' bg-primary/50 p-2'>
            <p className='text-left font-bold'>Øvrige fag:</p>
          </div>
        )}
        {courses.map((c) => (
          <VerificationLine key={c.courseCode + '-verification-line-c'} course={c} />
        ))}
        {sonansPack && (
          <div className=' bg-primary/50 w-full flex justify-between p-2'>
            <p className='text-left text-lg font-bold'>Sonanspakken</p>
            <p className='w-full text-right font-semibold'>15% rabatt</p>
          </div>
        )}
      </div>
      <div className='w-full border-b-2 border-black border-dashed mt-8 mb-8'></div>
      {total !== totalBefore && (
        <div className='w-full flex pl-2 pr-2'>
          <p className='w-full text-right text-xl font-semibold'>
            <span className='text-sm line-through text-text-grey'>{FormatPrice(totalBefore)},-</span>
          </p>
        </div>
      )}
      <div className='w-full flex pl-2 pr-2'>
        <p className='text-xl font-semibold w-full text-left'>Totalt</p>
        <p className='w-full text-right text-xl font-semibold'>
          <span className='bg-secondary'>{FormatPrice(total)},-</span>
        </p>
      </div>
      <p className='text-left pl-2 pr-2 mb-8'>
        *I tillegg til kursavgiften vil det påløpet en semesteravgift på 975,- per semester. Dette gjelder ikke ved kjøp
        av kun labkurs eller forkurs.
      </p>
      <div className='text-center'>
        <h3 className='font-bold text-lg'>Lurer du på noe?</h3>
        <p>Du kan avtale en gjennomgang av fagvalget ditt med en av våre rådgivere.</p>
        <p>Dette er helt gratis og sikrer at du får maksimalt utbytte av studietiden hos oss.</p>
      </div>
      <BookCounseling />
    </div>
  );
}
