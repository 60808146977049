import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
}

export function InfoExplanation({ className }: Props) {
  return (
    <div
      className={twMerge(
        'border border-primary text-center flex rounded-lg text-xs overflow-hidden w-full bg-primary/30',
        className
      )}>
      <div className='bg-primary flex items-center justify-center w-6'>
        <span className='block rotate-[-90deg] uppercase opacity-50'>Info</span>
      </div>
      <div className='p-2 lg:p-4'>
        <p>Klikk på INFO for å lese mer om et fag.</p>
      </div>
    </div>
  );
}
