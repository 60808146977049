import { PackStructure, selectedCourses } from '@/types';
import dataLayer from '@/utils/dataLayer';

interface Item {
  item_name: string;
  discount: number;
  item_type: string;
  item_brand: string;
  item_category: string;
  item_id: string;
  price: number;
  quantity: number;
}

export function beginCheckoutGTAG(
  courses: selectedCourses[],
  packs: PackStructure[],
  eventType: string,
  totalPrice: number
) {
  const items: Item[] = [];
  courses.forEach((c) => {
    const item = {
      item_name: c.selectedCourse.courseTitle,
      discount: 0,
      item_type: c.selectedCourse.time,
      item_brand: 'Sonans',
      item_category: 'fag',
      item_id: c.selectedCourse.courseId + c.selectedCourse.time,
      price: c.selectedCourse.price,
      quantity: 1,
    };

    items.push(item);
  });

  packs.forEach((p) => {
    const item = {
      item_name: p.title,
      discount: 0,
      item_type: '',
      item_brand: 'Sonans',
      item_category: 'pakke',
      item_id: p.lookup.text,
      price: p.price,
      quantity: 1,
    };

    items.push(item);
  });

  const event = {
    event: eventType,
    currency: 'NOK',
    value: totalPrice,
    items: items,
  };

  dataLayer.push(event);
}
