import {
  AppDispatch,
  CourseVariants,
  FilterType,
  ICourses,
  IPackageCoverage,
  IPlaces,
  ISemester,
  IStudyLoad,
  PackStructure,
  PlacementType,
  SemesterType,
  StructuredCourse,
  selectedCourses,
} from '@/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getSemesterStudyLoad, selectCourse, sortCourses } from './helpers';
import { addPack, getPackCourses } from '@/helpers/packHelpers';
import { addCourseGTAG } from '@/helpers/gtag/addCourse';
interface CartState {
  total: number;
  manuallySelectedPack: boolean;
  totalBefore: number;
  packCoverage: IPackageCoverage | null;
  selectedCourses: selectedCourses[];
  selectedPack: PackStructure | null;
  selectedCampus: IPlaces | null;
  preferredPlacement: PlacementType;
  filter: FilterType;
  studyLoad: IStudyLoad[];
  prevSem: boolean;
  sonansPack: boolean;
  recommendedSetup: boolean;
  previousState?: selectedCourses[];
}

const initialState: CartState = {
  recommendedSetup: true,
  manuallySelectedPack: false,
  total: 0,
  totalBefore: 0,
  packCoverage: null,
  preferredPlacement: 'online',
  selectedCourses: [],
  selectedPack: null,
  selectedCampus: null,
  filter: '',
  prevSem: false,
  sonansPack: false,
  previousState: undefined,
  studyLoad: [
    {
      exams: [],
      hours: 0,
      percent: 0,
      fullTime: false,
      warning: false,
    },
    {
      exams: [],
      hours: 0,
      percent: 0,
      fullTime: false,
      warning: false,
    },
  ],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setPackCoverage(state, action: PayloadAction<IPackageCoverage | null>) {
      state.packCoverage = action.payload;
    },
    setCart(state, action: PayloadAction<selectedCourses[]>) {
      state.selectedCourses = action.payload;
    },
    setTotal(state, action: PayloadAction<number>) {
      state.total = action.payload;
    },
    setTotalBefore(state, action: PayloadAction<number>) {
      state.totalBefore = action.payload;
    },
    setManuallySelectedPack(state, action: PayloadAction<boolean>) {
      state.manuallySelectedPack = action.payload;
    },
    setSonansPack(state, action: PayloadAction<boolean>) {
      state.sonansPack = action.payload;
    },
    removeCourseFromCart(state, action: PayloadAction<string>) {
      state.selectedCourses = state.selectedCourses.filter((course) => course.course.courseCode !== action.payload);
    },
    clearCart(state) {
      state.selectedCourses = [];
    },
    setPrevSem(state, action: PayloadAction<boolean>) {
      state.prevSem = action.payload;
    },
    switchSemester(
      state,
      action: PayloadAction<{
        semester: string;
        newCourse: CourseVariants;
        isUpgradeCourse: boolean;
        upgradeCourse: CourseVariants;
      }>
    ) {
      const { semester, newCourse, isUpgradeCourse, upgradeCourse } = action.payload;

      const index = state.selectedCourses.findIndex((ca) => ca.courseCode === newCourse.courseCode);

      let price = newCourse.price;
      if (newCourse.time === 'netbased') {
        if (semester === 'year' || semester === 'yearspring') {
          price = newCourse.priceNetFullyear;
        } else {
          price = newCourse.priceNetHalfyear;
        }
      }

      if (index !== -1) {
        state.selectedCourses[index].selectedCourse = newCourse;
        state.selectedCourses[index].selectedPrice = price;
        state.selectedCourses[index].selectedTime = newCourse.time;
        state.selectedCourses[index].selectedSemester = semester as SemesterType;
        state.selectedCourses[index].earlyStart = false;
        state.selectedCourses[index].manuallyMoved = true;
        if (isUpgradeCourse) {
          state.selectedCourses[index].upgradeCourses = [upgradeCourse];
        }
      }
    },
    setSelectedPack(state, action: PayloadAction<PackStructure | null>) {
      state.selectedPack = action.payload;
    },
    setSelectedCampus(state, action: PayloadAction<IPlaces | null>) {
      state.selectedCampus = action.payload;
    },
    setFilter(state, action: PayloadAction<FilterType>) {
      state.filter = action.payload;
    },
    setPerferredPlacement(state, action: PayloadAction<PlacementType>) {
      state.preferredPlacement = action.payload;
    },
    setStudyLoad(state, action: PayloadAction<IStudyLoad[]>) {
      state.studyLoad = action.payload;
    },
    setRecommendedSetup(state, action: PayloadAction<boolean>) {
      state.recommendedSetup = action.payload;
    },
  },
});

export const {
  setCart,
  setTotal,
  setTotalBefore,
  setPackCoverage,
  removeCourseFromCart,
  clearCart,
  setSelectedPack,
  setSelectedCampus,
  setFilter,
  setPerferredPlacement,
  setStudyLoad,
  switchSemester,
  setSonansPack,
  setManuallySelectedPack,
  setRecommendedSetup,
} = cartSlice.actions;

export function addToCart(
  course: StructuredCourse,
  selectedPlacement: Exclude<PlacementType, ''>,
  selectedCourses: selectedCourses[],
  time: SemesterType,
  skipFullYearCheck = false,
  selectedCampus: IPlaces | null
) {
  return (dispatch: AppDispatch) => {
    const courses = selectCourse(course, selectedPlacement, selectedCourses, time, skipFullYearCheck, selectedCampus);

    if (!courses) return;

    const currentSelectedCourse = courses.find((c) => c.courseCode === course.courseCode);

    if (currentSelectedCourse) {
      addCourseGTAG({ selectedCourse: currentSelectedCourse });
    }

    const sortedCourses = sortCourses(courses);

    dispatch(setCart(sortedCourses));
  };
}

export function addPackToCart({
  pack,
  replace = true,
  allStructuredCourses,
  courses,
  selectedCampus,
  selectedCourses,
}: {
  replace: boolean;
  pack: PackStructure;
  allStructuredCourses: StructuredCourse[];
  courses: ICourses[];
  selectedCampus: IPlaces | null;
  selectedCourses: selectedCourses[];
}) {
  return (dispatch: AppDispatch) => {
    const { tempC, tmp_courses } = getPackCourses({
      packObj: pack,
      allStructuredCourses,
      courses,
      selectedCampus,
      type: pack.placement,
    });

    const { courseArr } = addPack({
      replace,
      pack,
      selCourses: selectedCourses,
      packCourses: tempC,
      pCoursesStructured: tmp_courses,
    });

    const sortedCourses = sortCourses(courseArr);

    dispatch(setCart(sortedCourses));
  };
}

export function calculateStudyLoad(semesters: ISemester[], courses: selectedCourses[]) {
  return (dispatch: AppDispatch) => {
    const studyLoad: IStudyLoad[] = [];

    semesters.forEach((semester) => {
      studyLoad.push(getSemesterStudyLoad(courses, semester.semesterAlias));
    });

    dispatch(setStudyLoad(studyLoad));
  };
}
