import { clearCart, setPerferredPlacement, setSelectedCampus } from '@/reducers/cart';
import { AppDispatch, AppState, IPlaces, StructuredCourse } from '@/types';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from '../ui/useModal';
import { isAvailableOnCurrentCampus } from '@/helpers/courseHelpers';
import { twJoin, twMerge } from 'tailwind-merge';
import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';
import { Modal } from '../ui/Modal';
import { Button } from '../ui/Button';

interface Props {
  onClose: () => void;
  onSelect?: (location: IPlaces) => void;
  course?: StructuredCourse;
  overlay?: boolean;
  className?: string;
  target?: string;
  ignorePageScroll?: boolean;
}

export function PlacesDropdown({
  onClose,
  onSelect,
  course,
  overlay = false,
  className = '',
  target,
  ignorePageScroll,
}: Props) {
  const [showWarning, setShowWarning] = useState(false);
  const [pageScroll, setPageScroll] = useState(0);
  const [temporaryCampus, setTemporaryCampus] = useState<IPlaces | null>(null);
  const { campusLocations } = useSelector((state: AppState) => state.places);
  const { selectedCampus, selectedCourses } = useSelector((state: AppState) => state.cart);

  const dispatch = useDispatch<AppDispatch>();

  const { modalContentRef } = useModal({ onClose });

  useEffect(() => {
    if (ignorePageScroll) return;
    setPageScroll(window.scrollY);
  }, []);

  const content = (
    <>
      <div
        style={{ top: !ignorePageScroll && pageScroll > 0 ? `-${pageScroll}px` : '0' }}
        className={twMerge('absolute', className)}>
        <div
          onClick={onClose}
          className='fixed top-0 w-[100vw] h-[100svh] left-0 z-50 overflow-hidden bg-black/20'></div>
        <ul
          ref={modalContentRef}
          className='fixed text-xs 2xl:text-base animate-reveal text-left z-[51] bg-white p-2 rounded-lg border-primary border-2 flex flex-col gap-1 h-auto'>
          <h3 className='font-bold'>Velg by</h3>
          {campusLocations.map((location) => (
            <li className='flex gap-1 p-1 items-center' key={location.alias}>
              <input
                disabled={course ? !isAvailableOnCurrentCampus(course, location) : false}
                onChange={() => {
                  dispatch(setPerferredPlacement('campus'));
                  if (selectedCampus) {
                    if (selectedCampus.alias === location.alias) return;

                    if (selectedCourses.length > 0) {
                      setTemporaryCampus(location);
                      setShowWarning(true);
                      return;
                    }
                  }
                  dispatch(setSelectedCampus(location));
                  if (onSelect) {
                    onSelect(location);
                  }
                  onClose();
                }}
                checked={selectedCampus?.alias === location.alias}
                className='disabled:opacity-50 rounded-full checked:bg-black focus:ring-black focus:bg-black visible:hidden "cursor-pointer'
                id={location.alias}
                type='checkbox'
              />
              <label
                className={twJoin(
                  course && !isAvailableOnCurrentCampus(course, location) && 'opacity-50 line-through cursor-default',
                  'cursor-pointer'
                )}
                htmlFor={location.alias}>
                {location.name}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {showWarning && (
        <Modal className='z-[65]' mode='campus' onClose={() => setShowWarning(false)}>
          <div className='flex gap-2 text-center items-center justify-center flex-col'>
            <h2 className='font-bold text-xl'>Er du sikker på at du vil bytte campus?</h2>
            <p>Dine valgte fag vil forsvinne om du bytter campus</p>
            <div className='flex gap-2'>
              <Button
                onClick={() => {
                  dispatch(clearCart());
                  dispatch(setSelectedCampus(temporaryCampus))
                  onClose();
                }}
                width='full'
                size='medium'>
                Ja
              </Button>
              <Button onClick={() => setShowWarning(false)} width='full' size='medium' mode='secondary'>
                Nei
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );

  if (overlay) return createPortal(content, document.querySelector(target ? target : '#overlay') as HTMLElement);

  return content;
}
