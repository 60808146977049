import GetExamArray from "./libraries/GetExamArray"
import GetPackInfo from "./libraries/GetPackInfo"
import GetPackExtra from "./libraries/GetPackExtra"
import GetCourseRequirements from "../Functions/GetCourseRequirements"
import GetCourseInfo from "./libraries/GetCourseInfo"
import GetPrevExamArray from "./libraries/GetPrevExamArray"
import { ExamType, ICourseRequirements } from "@/types"

export function ExamArray(courseCode: string): ExamType[] {
    const exams = GetExamArray[courseCode]
    return exams || []
}

export function PrevExamArray(courseCode: string): ExamType[] {
    const exams = GetPrevExamArray[courseCode]
    return exams || []
}

export function PackInformation(alias: string): string[] {
    const info = GetPackInfo[alias]
    return info || []
}

export function CourseInformation(courseCode: string): (string | string[])[] {
    const info = GetCourseInfo[courseCode]
    return info || []
}

export function PackExtra(alias: string): string[] {
    const info = GetPackExtra[alias]
    return info || []
}


export function CourseRequirements(courseCode: string): ICourseRequirements[] {
    const requirements = GetCourseRequirements[courseCode];
    return requirements || undefined;
}