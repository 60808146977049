import { twJoin } from 'tailwind-merge';
import { IPlace } from '../../types';
import Campus from '../../assets/campus-v2.svg';
import Online from '../../assets/online-v2.svg';
import Live from '../../assets/live-v2.svg';
import { Underline } from '../ui/Underline';
import { useState } from 'react';

interface Props {
  place: IPlace;
}

export function PlaceCard({ place }: Props) {
  const [showDescription, setShowDescription] = useState(false);

  const colorClasses = {
    campus: 'border-2 border-primary',
    online: 'border-2 border-secondary',
    live: 'border-2 border-tertiary',
  };

  const bgClasses = {
    campus: 'bg-primary',
    online: 'bg-secondary',
    live: 'bg-tertiary',
  };

  const icons = {
    campus: Campus,
    online: Online,
    live: Live,
  };

  const underline = {
    campus: 'Fysisk',
    live: 'Digital',
    online: 'Nettstudier',
  };

  return (
    <article
      onClick={() => setShowDescription(!showDescription)}
      className={twJoin(
        'rounded-xl overflow-hidden w-full grow-0 self-start flex md:flex-col',
        colorClasses[place.alias]
      )}>
      <header
        className={twJoin(
          'w-32 px-2 md:h-40 md:px-0 2xl:h-60 md:w-full overflow-hidden flex-col flex gap-2 items-center justify-center',
          bgClasses[place.alias]
        )}>
        <img alt={place.alias} className='w-24 2xl:w-32' src={icons[place.alias]} />
        <div className='flex flex-col justify-center items-center'>
          <h2 className='font-bold text-lg hidden md:flex gap-1'>{place.name.split(' - ')[0]}</h2>
          <p className='hidden md:flex gap-1'>
            {<Underline content={place.name.split(' - ')[1]} word={underline[place.alias]} />}
          </p>
        </div>
      </header>
      <section
        className={twJoin(
          'py-2 px-2 md:px-4 text-sm 2xl:px-8 flex flex-col gap-2 w-full',
          showDescription ? 'h-auto' : 'h-30 lg:h-24'
        )}>
        <h2 className='font-bold self-start md:hidden flex gap-1'>
          {<Underline content={place.name} word={underline[place.alias]} />}
        </h2>
        <h3 className='font-bold hidden md:block lg:text-center'>{place.subTitle}</h3>
        <div className='[&>div>ul]:list-disc'>
          {!showDescription && (
            <div className='hidden md:block [&>ul]:list-inside lg:text-center'>{place.shortDescriptionDesktop}</div>
          )}
          {!showDescription && <div className='md:hidden pl-4'>{place.shortDescription}</div>}
          {showDescription && <div className='pl-4'>{place.description}</div>}
        </div>
        <button
          className={twJoin(
            'mt-auto self-center transition-all w-full flex items-center justify-center',
            showDescription ? 'rotate-180' : ''
          )}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-4 h-4 xl:h-5 xl:w-5'>
            <path strokeLinecap='round' strokeLinejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' />
          </svg>
        </button>
      </section>
    </article>
  );
}
