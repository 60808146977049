import { useEffect, useRef } from 'react';

interface Props {
  onClose: () => void;
}

// Modal hook to handle keyboard events and focus
export function useModal({ onClose }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalContentRef = useRef<any>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    const body = document.querySelector('body') as HTMLElement;

    body.style.setProperty('overflow', 'hidden');

    const modalContent = modalContentRef.current;

    if (modalContent) {
      modalContent.setAttribute('tabindex', '0');
      modalContent.focus();
    }

    return () => {
      // Clean up event listener, remove overflow hidden and focus
      window.removeEventListener('keydown', handleKeyDown);

      document.querySelector('body')?.style.removeProperty('overflow');

      if (modalContent) {
        modalContent.removeAttribute('tabindex');
        modalContent.blur();
      }
    };
  }, [onClose]);

  return { modalContentRef };
}
