import { Highlight } from '@/components/ui/Highlight';
import { getExamDate, getExams, translateExamType } from '@/reducers/helpers';
import { AppState, StructuredCourse, selectedCourses } from '@/types';
import { useSelector } from 'react-redux';

/**
 * Use one of the following props to render the component
 */
interface Props {
  structuredCourse?: StructuredCourse | null;
  selectedCourse?: selectedCourses | null;
}

export function CoursePopupExams({ structuredCourse, selectedCourse }: Props) {
  const { semesters } = useSelector((state: AppState) => state.semester);

  const exams = selectedCourse ? getExams(selectedCourse.course) : getExams(structuredCourse!);

  return (
    <ul>
      {exams.map((exam, i) => (
        <li className='flex' key={i}>
          <p className='flex flex-col lg:flex-row gap-0 lg:gap-1'>
            <strong className='flex'>
              {<Highlight content={translateExamType(exam.type)} highlight='eksamen' />}:
            </strong>
            {getExamDate(
              exam.type,
              selectedCourse ? selectedCourse.selectedSemester : semesters[0].semesterAlias,
              false,
              exam.date
            )}
          </p>
        </li>
      ))}
    </ul>
  );
}
