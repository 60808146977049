import { twMerge } from 'tailwind-merge';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  mode?: 'primary' | 'secondary' | 'outline';
  className?: string;
  width?: 'full' | 'auto';
  size?: 'small' | 'medium' | 'large' | 'full';
}

export function Button({
  children,
  mode = 'primary',
  width = 'full',
  size = 'large',
  className = '',
  ...props
}: Props) {
  const modeClasses = {
    primary: 'bg-primary text-black hover:bg-black hover:text-white',
    secondary: 'bg-black text-white hover:bg-primary hover:text-black',
    outline: 'border border-black bg-transparent text-black hover:bg-black hover:text-white',
  };

  const widthClasses = {
    full: 'w-full',
    auto: 'w-auto px-4',
  };

  const sizeClasses = {
    small: 'py-1 px-4 w-auto text-xs lg:text-sm',
    medium: 'py-1 w-32 px-4 text-base',
    large: 'py-2 px-8 text-base',
    full: 'py-4 px-8 w-full text-base',
  };

  return (
    <button
      {...props}
      className={twMerge(
        'self-start w-full bg-black py-2 relative text-white font-medium rounded-full',
        modeClasses[mode],
        sizeClasses[size],
        widthClasses[width],
        className,
        "disabled:bg-neutral-300 disabled:text-neutral-500 disabled:hover:text-neutral-500"
      )}>
      {children}
    </button>
  );
}
