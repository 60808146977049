import { useState } from 'react';
import { VerificationInfoFull } from './VerificationInfoFull';
import PriceGuarantee from '@/assets/garanti.svg?react';
import { VerificationInfo } from './VerificationInfo';
import { Button } from '../ui/Button';
import {Icon} from "@/components/ui/Icon.tsx";

export function VerificationInfoBoxes() {
  const [showLK, setShowLK] = useState<boolean>(false);
  const [showDB, setShowDB] = useState<boolean>(false);
  const [showFG, setShowFG] = useState<boolean>(false);

  function hideText() {
    setShowDB(false);
    setShowLK(false);
    setShowFG(false);
  }

  return (
    <div>
      {!showDB && !showLK && !showFG && (
        <div className='flex w-full justify-between gap-2 xl:gap-4 text-sm'>
          <VerificationInfo
            icon={<Icon className='w-16 lg:w-28' type='loan' />}
            onClick={() => {
              setShowLK(true);
            }}
            title='Lånekassen'
            description='Se hvor mye støtte du kan få.'
          />
          <VerificationInfo
            icon={<Icon className='w-12 lg:w-20' type='downpayment' />}
            onClick={() => {
              setShowDB(true);
            }}
            title='Delbetaling'
            description='Se når du får faktura.'
          />
          <VerificationInfo
            icon={<PriceGuarantee className='w-12 lg:w-20' />}
            onClick={() => {
              setShowFG(true);
            }}
            title={<>Fornøydhets&shy;garanti</>}
            description='Fornøyd eller faget på nytt!'
          />
        </div>
      )}

      {showLK && (
        <VerificationInfoFull
          onClick={hideText}
          icon={<Icon className='w-28' type='loan' />}
          title='Du kan søke lån og stipend fra lånekassen.'
          description={
            <div className='flex flex-col gap-2'>
              <div>
                <p>
                  Du kan få inntil 151.690,- i basislån per undervisningsår. Inntil 40 % av basislånet kan gjøres om til
                  stipend.
                </p>
                <p>Du kan få ekstralån til skolepenger på inntil kr 77.192,- per år.</p>
                <p>Du får støtte ut ifra studiebelastningen din.</p>
                <p>Her kan du beregne hvor mye støtte du kan få Lånekassens støttekalkulator.</p>
              </div>
              <a target='_blank' href='https://lanekassen.no/nb-NO/stipend-og-lan/norge/universitet-og-hogskole/'>
                <Button width='auto' size='medium'>
                  Lånekassen
                </Button>
              </a>
            </div>
          }
        />
      )}

      {showDB && (
        <VerificationInfoFull
          title='Delbetaling'
          onClick={hideText}
          icon={<Icon className='w-28' type='downpayment' />}
          description={
            <div>
              <p>14 dager etter påmelding vil du bli fakturert et depositum på 1.950,-</p>
              <p>Resten faktureres etter skolestart, når du skal ha fått støtte fra Lånekassen.</p>
            </div>
          }
        />
      )}

      {showFG && (
        <VerificationInfoFull
          title='Hva er fornøydhetsgaranti?'
          onClick={hideText}
          icon={<PriceGuarantee className='w-28' />}
          description={
            <div>
              <p>Hos oss skal alle bli fornøyde, derfor får du selvfølgelig fornøydhetsgaranti på Sonans.</p>
              <p>Fornøyd eller faget på nytt! Rett og slett. </p>
              <p>
                Skulle du av en eller annen grunn ha noe å utsette på på vårt undervisningsopplegg får du faget på nytt
                neste semester, gratis.
              </p>
            </div>
          }
        />
      )}
    </div>
  );
}
