import { Button } from '@/components/ui/Button';
import { Modal } from '@/components/ui/Modal';
import { ReadMoreButton } from '@/components/ui/ReadMoreButton';
import { CourseInformation } from '@/helpers/GetText/GetText';
import { capitalize, translateSemester } from '@/helpers/courseHelpers';
import { switchSemester } from '@/reducers/cart';
import {
  getCourseRequirements,
  getStudyLoad,
  hasOnlyOneSemester,
} from '@/reducers/helpers';
import { AppDispatch, AppState, selectedCourses } from '@/types';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CoursePopupExams } from './CoursePopupExams';
import { viewCourseGTAG } from '@/helpers/gtag/viewCourse';
import { useCoursePopup } from '@/components/courses/useCoursePopup.tsx';
import {Icon} from "@/components/ui/Icon.tsx";

interface Props {
  course: selectedCourses;
  onClose: () => void;
}

export function CourseCartPopup({ course, onClose }: Props) {
  const [showInfo, setShowInfo] = useState(false);

  const requirements = getCourseRequirements(course.course);

  const { selectedCampus } = useSelector((state: AppState) => state.cart);

  const isLab = course.courseCode.includes('lab-');
  const isIntro = course.courseCode.includes('intro-');

  const dispatch = useDispatch<AppDispatch>();

  const {
    isUpgradeCourse,
    hasAutumn,
    hasSpring,
    selectedSem,
    autumnCourse,
    springCourse,
    hasYear,
    yearCourse,
    hasSpringYear,
    springYearCourse,
    hasLabAutumn,
    hasLabSpring,
    autumnLabCourse,
    upgradeCourse,
    springLabCourse,
  } = useCoursePopup({ course });

  useEffect(() => {
    viewCourseGTAG({ course });
  }, [course]);

  return (
    <Modal id='course_popup' coursePopup onClose={onClose} mode={course.type}>
      <div className='text-sm flex flex-col gap-2'>
        <h2 className='text-center font-bold text-lg'>{course.course.courseTitle}</h2>
        {selectedSem === 'year' && (
          <li className='flex gap-1 mb-2'>
            <Icon className='w-4' type='arrow-thin-right' /> DEL 1 - Faget avsluttes våren 2026
          </li>
        )}
        <div>
          {!(isLab || isIntro) && (
            <div className='mb-2'>
              <CoursePopupExams selectedCourse={course} />
              <p>
                <strong>Fagkoder:</strong> {course.courseCode}
              </p>
              <p>
                <strong>Studiebelastning:</strong> {getStudyLoad(course)}%
              </p>
              {requirements && (
                <p>
                  <strong>Påkrevde forkunnskaper:</strong>{' '}
                  <span>
                    {requirements[0].courseNames.map((requirement, i) =>
                      i > 0 ? ` eller ${requirement}` : requirement
                    )}
                  </span>
                </p>
              )}
              <ReadMoreButton readMore={showInfo} onClick={() => setShowInfo(!showInfo)} />
            </div>
          )}
          {showInfo && (
            <ul className='list-disc text-left text-sm ml-12'>
              {CourseInformation(course.courseCode).map((info, i) => {
                const sanitizedInfo = typeof info === 'string' ? DOMPurify.sanitize(info) : '';
                return <li key={i} dangerouslySetInnerHTML={{ __html: sanitizedInfo }} />;
              })}
            </ul>
          )}
          {(isLab || isIntro) && (
            <ul className='list-disc text-left text-sm ml-12'>
              {CourseInformation(course.courseCode).map((info, i) => {
                const sanitizedInfo = typeof info === 'string' ? DOMPurify.sanitize(info) : '';
                return <li key={i} dangerouslySetInnerHTML={{ __html: sanitizedInfo }} />;
              })}
            </ul>
          )}
        </div>
        <div className='flex w-full gap-2'>
          {!isUpgradeCourse && (
            <>
              {hasAutumn && selectedSem !== 'autumn' && (
                <Button
                  size='medium'
                  width='full'
                  onClick={() => {
                    dispatch(
                      switchSemester({ semester: 'autumn', newCourse: autumnCourse, isUpgradeCourse, upgradeCourse })
                    );
                    onClose();
                  }}>
                  Flytt til høst
                </Button>
              )}
              {hasSpring && selectedSem !== 'spring' && (
                <Button
                  size='medium'
                  width='full'
                  onClick={() => {
                    dispatch(
                      switchSemester({ semester: 'spring', newCourse: springCourse, isUpgradeCourse, upgradeCourse })
                    );
                    onClose();
                  }}>
                  Flytt til vår
                </Button>
              )}
              {hasYear && selectedSem !== 'year' && selectedSem === 'autumn' && (
                <Button
                  size='medium'
                  width='full'
                  onClick={() => {
                    dispatch(
                      switchSemester({ semester: 'year', newCourse: yearCourse, isUpgradeCourse, upgradeCourse })
                    );
                    onClose();
                  }}>
                  Endre til helår
                </Button>
              )}
              {hasSpringYear && selectedSem !== 'yearspring' && selectedSem === 'spring' && (
                <Button
                  size='medium'
                  width='full'
                  onClick={() => {
                    dispatch(
                      switchSemester({
                        semester: 'yearspring',
                        newCourse: springYearCourse,
                        isUpgradeCourse,
                        upgradeCourse,
                      })
                    );
                    onClose();
                  }}>
                  Endre til helår
                </Button>
              )}
              {hasLabAutumn && selectedSem !== 'lab_practice_autum' && (
                <Button
                  size='medium'
                  width='full'
                  onClick={() => {
                    dispatch(
                      switchSemester({
                        semester: 'lab_practice_autum',
                        newCourse: autumnLabCourse,
                        isUpgradeCourse,
                        upgradeCourse,
                      })
                    );
                    onClose();
                  }}>
                  Flytt til høst
                </Button>
              )}
              {hasLabSpring && selectedSem !== 'lab_practice_spring' && (
                <Button
                  size='medium'
                  width='full'
                  onClick={() => {
                    dispatch(
                      switchSemester({
                        semester: 'lab_practice_spring',
                        newCourse: springLabCourse,
                        isUpgradeCourse,
                        upgradeCourse,
                      })
                    );
                    onClose();
                  }}>
                  Flytt til vår
                </Button>
              )}
            </>
          )}
          {isUpgradeCourse && (
            <div className='flex gap-2'>
              {hasAutumn && selectedSem === 'autumn' && (
                <Button
                  size='medium'
                  width='full'
                  onClick={() => {
                    dispatch(
                      switchSemester({ semester: 'autumn', newCourse: autumnCourse, isUpgradeCourse, upgradeCourse })
                    );
                    onClose();
                  }}>
                  Endre til{' '}
                  {course.selectedCourse.courseTitle
                    .replace('Matematikk', 'Matte')
                    .replace('matematikk', 'matte')
                    .replace(' med teskje', '')
                    .replace(' bestått', '')}
                </Button>
              )}
              {hasSpring && selectedSem === 'spring' && (
                <Button
                  size='medium'
                  width='full'
                  onClick={() => {
                    dispatch(
                      switchSemester({ semester: 'spring', newCourse: springCourse, isUpgradeCourse, upgradeCourse })
                    );
                    onClose();
                  }}>
                  Endre til{' '}
                  {course.selectedCourse.courseTitle
                    .replace('Matematikk', 'Matte')
                    .replace('matematikk', 'matte')
                    .replace(' med teskje', '')
                    .replace(' bestått', '')}
                </Button>
              )}
              {hasYear && selectedSem === 'year' && (
                <Button
                  size='medium'
                  width='full'
                  onClick={() => {
                    dispatch(
                      switchSemester({ semester: 'year', newCourse: yearCourse, isUpgradeCourse, upgradeCourse })
                    );
                    onClose();
                  }}>
                  Endre til{' '}
                  {course.selectedCourse.courseTitle
                    .replace('Matematikk', 'Matte')
                    .replace('matematikk', 'matte')
                    .replace(' med teskje', '')
                    .replace(' bestått', '')}
                </Button>
              )}
            </div>
          )}
        </div>
        <div>
          {hasOnlyOneSemester(course.course, course.type, selectedCampus) && (
            <p className='font-bold text-center'>
              Kun tilgjengelig på {translateSemester(course.selectedTime)}{' '}
              {selectedCampus && course.type === 'campus'
                ? `i ${selectedCampus.name}`
                : `på ${capitalize(course.type)}`}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
}
