import { useSelector } from 'react-redux';
import { InfoExplanation } from './InfoExplanation';
import { PlacementInfo } from './PlacementIInfo';
import { PracticalInfoContainer } from './PracticalInfoContainer';
import { SecondSemesterYearInfo } from './SecondSemesterYearInfo';
import { StudyLoadInfo } from './StudyLoadInfo';
import { AppState } from '@/types';
import { twJoin } from 'tailwind-merge';

export function PracticalInfo() {
  const selectedCourses = useSelector((state: AppState) => state.cart.selectedCourses);

  const hasYear = selectedCourses.some((course) => course.selectedSemester === 'year');


  return (
    <PracticalInfoContainer>
      <div className='grid grid-cols-12 gap-2'>
        <StudyLoadInfo className={twJoin(hasYear ? 'col-span-12 lg:col-span-4' : 'col-span-12 lg:col-span-6')} />
        {hasYear && <SecondSemesterYearInfo className='col-span-6 lg:col-span-2' />}
        <InfoExplanation className={twJoin('col-span-6 lg:col-span-2')} />
        <PlacementInfo className={twJoin(hasYear ? 'col-span-12 lg:col-span-4' : 'col-span-6 lg:col-span-4')} />
      </div>
    </PracticalInfoContainer>
  );
}
