import { selectedCourses } from '@/types';
import dataLayer from '@/utils/dataLayer';

export function viewCourseGTAG({ course }: { course: selectedCourses }) {
  const event = {
    event: 'view_item',
    currency: 'NOK',
    value: course.selectedPrice,
    items: [
      {
        item_name: course.selectedCourse.courseTitle,
        discount: 0,
        item_type: course.selectedCourse.time,
        item_brand: 'Sonans',
        item_category: 'fag',
        item_id: course.selectedCourse.courseId + course.selectedCourse.time,
        price: course.selectedCourse.price,
        quantity: 1,
      },
    ],
  };

  dataLayer.push(event);
}
