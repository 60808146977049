import { AppState, PlacementType, StructuredCourse } from '@/types';
import { ShortenCourseName } from '@/helpers/Functions/ShortenCourseName';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FormatPrice from '@/helpers/Functions/FormatPrice';
import { CoursePopup } from './popups/CoursePopup';
import { getVariantPrice } from '@/reducers/helpers';
import { CourseInfoTag } from './CourseInfoTag';
import { hasVariants, resolveVariantType } from '@/helpers/courseHelpers';
import { schools } from './courseSearch/CourseSearchSemesterIcons';
import { CourseItemButton } from './CourseItemButton';

interface Props {
  course: StructuredCourse;
  onSelectCourse: (course: StructuredCourse, placement: string) => void;
}

/**
 * Course item that is used in the course search
 */
export function CourseItem({ course }: Props) {
  const [selectedPlacement, setSelectedPlacement] = useState<PlacementType>('');
  const [coursePrice, setCoursePrice] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);

  const { selectedCampus, preferredPlacement } = useSelector((state: AppState) => state.cart);
  const { selectedCourses } = useSelector((state: AppState) => state.cart);

  useEffect(() => {
    const courseInCart = selectedCourses.find((c) => c.course.courseCode === course.courseCode);
    if (courseInCart) {
      setSelectedPlacement(courseInCart.type);
    } else {
      setSelectedPlacement('');
    }
  }, [selectedCourses, course.courseCode]);

  useEffect(() => {
    const price = getVariantPrice(course, selectedPlacement ? selectedPlacement : preferredPlacement);
    setCoursePrice(price);
  }, [course, selectedPlacement, preferredPlacement]);

  if (!hasVariants(course, selectedCampus)) return null;

  return (
    <li
      data-testid={'course-item-' + course.courseCode}
      className='w-full grid grid-cols-[3fr,2fr] md:grid-cols-[3fr,1fr] gap-2'>
      <div className='flex'>
        <button
          onClick={() => setShowModal(true)}
          className='flex overflow-hidden rounded-lg w-full h-[90%] border shadow-md border-neutral-300 gap-2 items-center'>
          <CourseInfoTag placement={resolveVariantType({ course, selectedCampus, preferredPlacement, selectedPlacement })} />
          <div className='flex justify-between items-center w-full pr-2'>
            <p className='font-medium text-left'>
              {ShortenCourseName(course.courseTitle)
                .replace('Matematikk', 'Matte')
                .replace('matematikk', 'matte')
                .replace('Engelsk 1', 'Engelsk 1 (valgfritt programfag)')
                .replace('Engelsk 2', 'Engelsk 2 (valgfritt programfag)')
                .replace('Engelsk VG1', 'Engelsk VG1 (obligatorisk fellesfag)')
                .replace('PB', '')
                .replace(', BUA Vg2', '')
                .replace(', BUA prakiskandidat', '')
                .replace(', HEA praksiskandidat', '')
                .replace(', HSF Vg1', '')
                .replace(', HES Vg2', '')
                .replace(', HSE Vg3', '')
                .replace(', HEA Vg2', '')}
            </p>
            <p className='text-right w-1/3'>
              {FormatPrice(coursePrice)}
              {',-'}
            </p>
          </div>
        </button>
      </div>
      <div className='flex gap-1'>
        {schools.map((school) => (
          <CourseItemButton key={school.alias} school={school} course={course} />
        ))}
      </div>
      {showModal && (
        <CoursePopup
          mode={resolveVariantType({ course, selectedCampus, preferredPlacement, selectedPlacement })}
          course={course}
          onClose={() => setShowModal(false)}
        />
      )}
    </li>
  );
}
