import { twMerge } from 'tailwind-merge';
import { schools } from '../courses/courseSearch/CourseSearchSemesterIcons';
import { PlacementInfoItem } from './PlacementInfoItem';

interface Props {
  className?: string;
}

export function PlacementInfo({ className }: Props) {
  return (
    <div className={twMerge('grid grid-cols-3 w-full gap-2', className)}>
      {schools.map((school) => (
        <PlacementInfoItem key={school.name} placement={school} />
      ))}
    </div>
  );
}
