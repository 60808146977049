import { findPackCourseCodes } from '@/helpers/PackageCoursePicker/PackageCoursePicker';
import { calculatePackageCoverage } from '@/helpers/PackageCoverage/PackageCoverage';
import {
  calculateStudyLoad,
  setCart,
  setPackCoverage,
  setSelectedPack,
  setSonansPack,
  setTotal,
  setTotalBefore,
} from '@/reducers/cart';
import { sortCourses } from '@/reducers/helpers';
import { AppDispatch, AppState, IPackageCoverage, PackStructure, selectedCourses } from '@/types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Logic from existing SemesterCourseContainer component. Price calculation and pack handling.
// TODO: Move to redux later
export function useSemesterCourseContainer() {
  const [willGetNormalPack, setWillGetNormalPack] = useState<IPackageCoverage | null>(null);
  const [hasYear, setHasYear] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const { selectedCourses, selectedPack, selectedCampus } = useSelector((state: AppState) => state.cart);
  const { semesters } = useSelector((state: AppState) => state.semester);
  const { structuredPacks } = useSelector((state: AppState) => state.packs);
  const { structuredCourses } = useSelector((state: AppState) => state.courses);

  useEffect(() => {
    dispatch(calculateStudyLoad(semesters, selectedCourses));
  }, [selectedCourses, semesters, dispatch]);

  function setPack(pack: IPackageCoverage) {
    const selPack: PackStructure = {
      agreements: pack.package.agreements,
      alias: pack.package.alias,
      courses: pack.package.courses,
      lookup: pack.package.lookup,
      placement: pack.package.placement,
      price: pack.package.price,
      title: pack.package.title,
    };
    dispatch(setSelectedPack(selPack));
  }

  useEffect(() => {
    let total = 0;
    let totalBefore = 0;

    selectedCourses.forEach((c) => {
      totalBefore += c.selectedPrice;
      if (!c.packCourse) {
        total += c.selectedPrice;
      }
    });

    const packageCoverage = calculatePackageCoverage(
      selectedCourses,
      structuredPacks,
      structuredCourses,
      selectedCampus ? selectedCampus.alias : 'sonans'
    );

    if (packageCoverage.length > 0) {
      const normalPack = packageCoverage[0];
      setWillGetNormalPack(normalPack);
      dispatch(setPackCoverage(normalPack));
      total += normalPack.priceAdjustment;

      if (!selectedPack || normalPack.package.alias !== selectedPack.alias) {
        setPack(normalPack);
      }
    } else {
      if (selectedPack) {
        dispatch(setSelectedPack(null));
      }
      setWillGetNormalPack(null);
      dispatch(setPackCoverage(null));
    }

    if (selectedPack) {
      total += selectedPack.price;
    }

    dispatch(setTotal(total));

    if (packageCoverage.length > 0) {
      dispatch(setTotalBefore(totalBefore));
    } else {
      dispatch(setTotalBefore(totalBefore));
    }

    const yearCourses = selectedCourses.filter((c) => !c.earlyStart && c.selectedSemester === 'yearspring');
    setHasYear(yearCourses.length > 0);
  }, [selectedCourses]);

  useEffect(() => {
    //update price total and saving
    let total = 0;
    let totalBefore = 0;
    selectedCourses.forEach((c) => {
      if (c.packCourse === false) {
        total += c.selectedPrice;
      }
      totalBefore += c.selectedPrice;
    });

    if (willGetNormalPack) {
      total += willGetNormalPack.priceAddedCourses - willGetNormalPack.savingsIfAdded;
    }

    dispatch(setTotal(total));
    dispatch(setTotalBefore(totalBefore));
  }, [selectedCourses, selectedPack, willGetNormalPack, dispatch]);

  function updatePackStatusCourses(courses: selectedCourses[], pack: PackStructure) {
    let packCourses: string[] = [];

    if (pack && pack.courses) {
      packCourses = findPackCourseCodes(pack.courses, structuredCourses);
    }

    const updatedCourses = courses.map((course) => {
      if (packCourses.indexOf(course.courseCode) !== -1 || (pack && pack.alias === 'sonanspack')) {
        return {
          ...course,
          packCourse: true,
          pack: pack.alias,
          packTitle: pack.title,
        };
      } else {
        return {
          ...course,
          packCourse: false,
          pack: '',
          packTitle: '',
        };
      }
    });

    if (JSON.stringify(courses) !== JSON.stringify(updatedCourses)) {
      const sortedCourseArr = sortCourses(updatedCourses);
      dispatch(setCart(sortedCourseArr));
    }
  }

  function removePackStatusCourses(courses: selectedCourses[]) {
    const updatedCourses = courses.map((course) => ({
      ...course,
      packCourse: false,
      pack: '',
      packTitle: '',
    }));

    if (JSON.stringify(courses) !== JSON.stringify(updatedCourses)) {
      const sortedCourseArr = sortCourses(updatedCourses);
      dispatch(setCart(sortedCourseArr));
    }
  }

  useEffect(() => {
    if (selectedPack && selectedCourses.length > 0) {
      updatePackStatusCourses(selectedCourses, selectedPack);
    } else if (!selectedPack && selectedCourses.length > 0) {
      removePackStatusCourses(selectedCourses);
    }
  }, [selectedPack, selectedCourses]);

  useEffect(() => {
    const fullCourses = selectedCourses.filter(
      (c) => c.packCourse === false && !c.courseCode.includes('intro-') && !c.courseCode.includes('lab-')
    );

    let packAlias = '';
    if (selectedPack) {
      packAlias = selectedPack.alias;
    }

    if (fullCourses.length > 5 || packAlias === 'sonanspack') {
      dispatch(setSonansPack(true));
    }
  }, []);

}
