import {
    IconTypes,
    PackStructureCourse,
    selectedCourses,
    StructuredCourse
} from "@/types";
import { isOfferedStrucutredCourse } from "../CourseFinder/CourseFinder";
import GetCourseRequirements from "../Functions/GetCourseRequirements";
import { FindOppositeSemester } from "../Functions/CheckSemester";
import { GetAvailableSemesters } from "../Functions/GetAvailableSemesters";
import { getSemesterPackCourses } from "../GetText/libraries/GetPacks";

function findStructuredCourse(course: PackStructureCourse, courses: StructuredCourse[]) {
    const allCourses = courses.map((c) => { return c.variants }).flat(1);
    const ourCourses = allCourses.filter((c) => c.courseId === course.course_id);
    if (ourCourses.length > 0) {
        return ourCourses[0];
    }
    return undefined;
}

export function findFirstSem() {
    const availableSemesters = GetAvailableSemesters(false)
    return availableSemesters[0].firstSemester
}

export function findOfferedPackageCourse(course: PackStructureCourse, placement: IconTypes, courses: StructuredCourse[], agreementAlias: string, packCourses: PackStructureCourse[]) {
    // List of all course codes that are in any way shape or form a pre-requisite to another course code
    const requirements = GetCourseRequirements;
    const reqCodes = Object.keys(requirements);
    
    const preReqCodes = reqCodes.map(function (v) {
        return requirements[v].map(function (w) {
            return w.courseCodes;
        });
    }).flat(Infinity);

    const courseCodes = findPackCourseCodes(packCourses, courses)
    const courseCode = findCourseCode(course, courses)
    let sem = placement === 'online' ? course.alternative_type : course.semester_length_alias

    const courseIsReq = reqCodes.indexOf(courseCode) !== -1 ? true : false
    const courseIsPreReq = preReqCodes.indexOf(courseCode) !== -1 ? true : false

    let packContainsReq: boolean = false
    if (courseIsPreReq) {
        reqCodes.forEach((r) => {
            requirements[r].forEach((pr) => {
                if (pr.courseCodes.indexOf(courseCode) !== -1) {
                    if (courseCodes.indexOf(r) !== -1) {
                        packContainsReq = true
                    }
                }
            })
        })
    }


    const currentSem = findFirstSem()

    if (currentSem === 'spring' && courseCode === 'NOR1267/NOR1268/NOR1269') {
        sem = 'yearspring'
    }

    const oppositeSem = FindOppositeSemester(sem)
    const isOfferedCampus = isOfferedStrucutredCourse(course, placement, courses, sem, course.alternative_type, agreementAlias);
    const isOfferedLive = isOfferedStrucutredCourse(course, 'live', courses, sem, course.alternative_type, 'sondig');
    const isOfferedOnline = isOfferedStrucutredCourse(course, 'online', courses, 'netbased', sem, 'sonans');

    if (placement === 'campus') {
        if (isOfferedCampus) {
            return isOfferedCampus
        } else if (!isOfferedCampus && isOfferedLive) {
            return isOfferedLive
        } else if (!isOfferedCampus && !isOfferedLive && isOfferedOnline) {
            return isOfferedOnline
        }
    } else if (placement === 'live') {
        if (isOfferedLive) {
            return isOfferedLive
        } else {
            return isOfferedOnline
        }
    } else if (placement === 'online') {
        return isOfferedOnline
    }
    return undefined
}

function findParentTime(packCourse: PackStructureCourse, courses: selectedCourses[], allCourses: StructuredCourse[], placement: IconTypes) {
    const structuredCourse = findStructuredCourse(packCourse, allCourses);
    if (structuredCourse) {
        const courseCode = structuredCourse.courseCode;
        const findCourseCode = courseCode.replace('lab-', '');
        const foundCourse = courses
            .filter((c) => c.selectedCourse.courseCode === findCourseCode);
        if (foundCourse.length > 0) {
            return foundCourse[0].type;
        }
    }

    return placement;
}


export function findOfferedPackageCourses(packCourses: PackStructureCourse[], placement: IconTypes, courses: StructuredCourse[], agreementAlias: string, packName: string) {
    const packTitle = packName.replace(' online', '').replace(' live', '')
    const currentSem = findFirstSem()
    const getPacksCourses = getSemesterPackCourses(packTitle, agreementAlias, currentSem)
    let foundCourses = [];
    const labSemesterLengths = [''];
    for (let i = 0; i < packCourses.length; i++) {
        const courseCode = findCourseCode(packCourses[i], courses)
        const getPackCourse = getPacksCourses?.filter((c) => c.coursecode === courseCode)
        let place = placement
        const selPackCourse = { ...packCourses[i] }
        if (getPackCourse?.length > 0) {
            place = getPackCourse[0].place as IconTypes
            if (place === 'online') {
                selPackCourse.alternative_type = getPackCourse[0].semester
                selPackCourse.semester_length_alias = 'netbased'
            } else {
                selPackCourse.semester_length_alias = getPackCourse[0].semester
            }
        }
        const course = findOfferedPackageCourse(selPackCourse, place, courses, agreementAlias, packCourses);
        if (course) {
            foundCourses.push(course);
        }
    }

    //
    /* for (var i = 0; i < packCourses.length; i++) {
        if (labSemesterLengths.indexOf(packCourses[i].semester_length_alias) !== -1) {
            const labCoursePlacement = findParentTime(packCourses[i], foundCourses, courses, placement);
            const course = findOfferedPackageCourse(packCourses[i], labCoursePlacement, courses, agreementAlias, packCourses);
            if (course) {
                foundCourses.push(course);
            }
        }
    } */

    const invalidCourseCodes = foundCourses
        .filter((c) => c.type === 'online')
        .map((c) => { return 'lab-' + c.selectedCourse.courseCode });

    foundCourses = foundCourses
        .filter((c) => invalidCourseCodes.indexOf(c.selectedCourse.courseCode) === -1)

    //const tmp = distributeCourses(foundCourses, agreementAlias);
    //return tmp;
    return foundCourses
}



export function findPackCourseCodes(packCourses: PackStructureCourse[], courses: StructuredCourse[]) {
    const courseCodes: string[] = []
    packCourses.forEach((pc) => {
        courses.forEach((c) => {
            c.variants.forEach((cv) => {
                if (cv.courseId === pc.course_id && courseCodes.indexOf(c.courseCode) === -1) {
                    courseCodes.push(c.courseCode)
                }
            })
        })
    })

    return courseCodes
}

export function findCourseCode(packCourse: PackStructureCourse, courses: StructuredCourse[]) {
    let courseCode: string = ''
    courses.forEach((c) => {
        c.variants.forEach((cv) => {
            if (cv.courseId === packCourse.course_id) {
                courseCode = c.courseCode
            }
        })
    })

    return courseCode
}