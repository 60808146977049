import {
    IconTypes,
    IPackageCoverage,
    PackStructure,
    selectedCourses, StructuredCourse
} from "@/types";
import { findOfferedPackageCourse } from "../PackageCoursePicker/PackageCoursePicker"

function sumPrice(prices: number[]) {
    let sum = 0;
    for (var i = 0; i < prices.length; i++) {
        sum += prices[i] || 0;
    }
    return sum;
}

function reduceAndSumPrice(courses: selectedCourses[]) {
    const prices = courses
        .map((course) => { return course.selectedCourse.price; })
    return sumPrice(prices);
}

function findAcceptedPlacements(placement: string) {
    if (placement === 'campus') {
        return ['campus', 'live', 'online'];
    } else if (placement === 'live') {
        return ['live', 'online'];
    } else {
        return ['online']
    }
}

function isAdded(course: selectedCourses, gotCourses: selectedCourses[]) {
    const acceptedPlacements = findAcceptedPlacements(course.type);
    for (let i = 0; i < gotCourses.length; i++) {
        if (gotCourses[i].courseCode == course.courseCode) {
            if (acceptedPlacements.indexOf(gotCourses[i].type) !== -1) {
                let differencePrice = gotCourses[i].selectedPrice - course.selectedPrice;
                if (gotCourses[i].type === 'online') {
                    if (gotCourses[i].selectedSemester === 'year' || gotCourses[i].selectedSemester === 'yearspring') {
                        differencePrice = gotCourses[i].selectedCourse.priceNetFullyear - course.selectedPrice;
                    } else {
                        differencePrice = gotCourses[i].selectedCourse.priceNetHalfyear - course.selectedPrice;
                    }
                }
                if (differencePrice > 0) {
                    return { found: true, diff: differencePrice, priceInCart: gotCourses[i].selectedPrice };
                } else {
                    return { found: true, diff: 0, priceInCart: gotCourses[i].selectedPrice };
                }
            }
        }
    }

    return { found: false, diff: 0, priceInCart: 0 };
}

export function calculatePackageCoverage(gotCourses: selectedCourses[], coursePacks: PackStructure[], courses: StructuredCourse[], agreementAlias: string) {
    const hasCampus = gotCourses.filter((course) => course.type === 'campus').length > 0;
    const hasLive = gotCourses.filter((course) => course.type === 'live').length > 0;
    const hasOnline = gotCourses.filter((course) => course.type === 'online').length > 0;

    const possibleCoursePacks = coursePacks.filter((pack) =>
        (hasCampus && pack.placement === 'campus' || (hasLive && pack.placement === 'live') || (hasOnline && pack.placement === 'online'))
    );

    const packsCoverage: IPackageCoverage[] = possibleCoursePacks
        .map((pack) => {
            const packCourses = pack.courses;
            const missing: selectedCourses[] = [];
            const got: selectedCourses[] = [];
            const pricesInCart: number[] = [];
            let nrCourses = 0;
            let priceAdjustment = 0;

            if (packCourses) {
                nrCourses = packCourses.length;
                packCourses
                    .forEach((course) => {
                        //const oneCourse = isOfferedStrucutredCourse(course, pack.placement, courses, course.semester_length_alias, course.alternative_type, agreementAlias);
                        const oneCourse = findOfferedPackageCourse(course, pack.placement, courses, agreementAlias, packCourses);

                        if (oneCourse) {
                            const foundInCart = isAdded(oneCourse, gotCourses);
                            if (foundInCart.found) {
                                priceAdjustment = priceAdjustment + foundInCart.diff;
                                got.push(oneCourse);
                                pricesInCart.push(foundInCart.priceInCart);
                            } else {
                                missing.push(oneCourse);
                            }
                        }
                    }
                    )
            }

            const priceAddedCourses: number = sumPrice(pricesInCart);
            const priceMissingCourses: number = reduceAndSumPrice(missing);
            const savingsIfAdded = priceAddedCourses - pack.price - priceAdjustment;

            return {
                packageName: pack.title,
                priceAdjustment: priceAdjustment,
                package: pack,
                addedCourses: got,
                missingCourses: missing,
                savingsIfAdded: savingsIfAdded,
                priceAddedCourses: priceAddedCourses,
                nrCourses: nrCourses,
                priceMissingCourses: priceMissingCourses
            };
        })

    // If you can have sonans pack, check the discount on this
    let sonansPackDiscount = 0;
    let total = 0;
    gotCourses.forEach((c) => { total = total + c.selectedPrice })

    const fullCourses = gotCourses.filter((sc) => (!sc.courseCode.includes('intro-') && !sc.courseCode.includes('lab-')));
    if (fullCourses.length > 5) {
        sonansPackDiscount = total * 0.15;

        let sonansPackPlacement = 'online';
        let sonansPackOnline = true;
        if (hasCampus) {
            sonansPackPlacement = 'campus';
            sonansPackOnline = false;
        } else if (hasLive) {
            sonansPackPlacement = 'live';
            sonansPackOnline = false;
        }

        const sonansPack: IPackageCoverage = {
            packageName: 'Sonanspakken',
            priceAdjustment: 0,
            package: {
                title: 'Sonanspakken',
                alias: 'sonanspack',
                price: total * 0.85,
                agreements: [],
                lookup: { key: '', text: '' },
                placement: sonansPackPlacement as IconTypes,
                courses: [],
            },
            addedCourses: gotCourses,
            missingCourses: [],
            savingsIfAdded: sonansPackDiscount,
            priceAddedCourses: total,
            nrCourses: gotCourses.length,
            priceMissingCourses: 0
        };
        packsCoverage.push(sonansPack);
    }

    const packsInPlay = packsCoverage
        .filter((pack) => pack.addedCourses.length > 0 && pack.nrCourses > 0)
        .filter((pack) => pack.savingsIfAdded > 0)
        .sort((a, b) => { return a.savingsIfAdded > b.savingsIfAdded ? -1 : 1 });
        
    return packsInPlay
}