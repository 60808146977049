import { PlaceCard } from '../components/places/PlaceCard';
import { Button } from '../components/ui/Button';
import { PageTitle } from '../components/ui/PageTitle';
import { Container } from '../components/ui/Container';
import { IPlace } from '../types';
import { Link } from 'react-router-dom';


/**
 * Page that displays information about the different offerings (campus, live and online)
 */
export function Places() {

  // TODO: Replace this with a call to the API in the future
  const places: IPlace[] = [
    {
      id: 1,
      name: 'Campus - Fysisk undervisning',
      alias: 'campus',
      subTitle: 'Oppmøte i klasserom til faste tider.',
      shortDescription: (
        <ul>
          <li>Oppmøte i klasserom til faste tider</li>
          <li>Campuser i 9 byer</li>
        </ul>
      ),
      shortDescriptionDesktop: (
        <ul>
          <li>Campuser i 9 byer</li>
        </ul>
      ),
      description: (
        <ul>
          <li className='md:hidden'>Oppmøte i klasserom til faste tider.</li>
          <li>Campuser i 9 byer</li>
          <li>Strukturert studiehverdag</li>
          <li>Grupperom og leseplasser</li>
          <li>Sosialt studentmiljø</li>
          <li>Arrangementer</li>
          <li>Leksehjelp</li>
        </ul>
      ),
    },
    {
      id: 3,
      name: 'Live - Digital undervisning',
      alias: 'live',
      subTitle: 'Livestreamet til faste tider.',
      shortDescription: (
        <ul>
          <li>Livestreamet til faste tider</li>
          <li>Fleksibilitet og struktur</li>
        </ul>
      ),
      shortDescriptionDesktop: (
        <ul>
          <li>Fleksibilitet og struktur</li>
        </ul>
      ),
      description: (
        <ul>
          <li className='md:hidden'>Livestreamet til faste tider</li>
          <li>Fleksibilitet og struktur</li>
          <li>Direkte videoundervisning med fast timeplan</li>
          <li>Repeter med opptak</li>
          <li>Studer fra hvor du vil</li>
          <li>Mulighet for aktiv deltakelse i undervisningen</li>
        </ul>
      ),
    },
    {
      id: 2,
      alias: 'online',
      name: 'Online - Nettstudier',
      subTitle: 'Når du vil, med lærerstøtte.',
      shortDescription: (
        <ul>
          <li>Når du vil, med lærerstøtte.</li>
          <li>Studer i eget tempo</li>
        </ul>
      ),
      shortDescriptionDesktop: (
        <ul>
          <li>Studer i eget tempo</li>
        </ul>
      ),
      description: (
        <ul>
          <li className='md:hidden'>Når du vil, med lærerstøtte</li>
          <li>Studer i eget tempo</li>
          <li>Mange selvtester og valgfrie innsendingsoppgaver</li>
          <li>Videoundervisning i kombinasjon med fagteori</li>
          <li>Faglærer hjelper deg</li>
        </ul>
      ),
    },
  ];

  return (
    <div className='flex flex-col gap-4 pb-4'>
      <PageTitle>Våre undervisningsformer</PageTitle>
      <section className='flex flex-col lg:flex-row gap-2 xl:gap-4 text-sm'>
        {places.map((place) => (
          <PlaceCard key={place.id} place={place} />
        ))}
      </section>
      <div className='fixed tall:relative bottom-0 left-0 py-4 xl:pt-4 lg:pb-14 w-full bg-white'>
        <Container className='tall:w-auto tall:px-0 flex items-center justify-center'>
          <Link className='w-auto lg:w-full' to='/fag'>
            <Button className='w-auto lg:w-full' mode='secondary'>Fag og priser</Button>
          </Link>
        </Container>
      </div>
    </div>
  );
}
