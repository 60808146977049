
import {
  findOfferedPackageCourses,
  findPackCourseCodes,
} from '@/helpers/PackageCoursePicker/PackageCoursePicker';
import { CourseFromId } from '@/helpers/Translators/CourseFromId';
import {
  CourseVariants,
  ICourse,
  ICourses,
  IPlaces,
  IconTypes,
  PackStructure,
  StructuredCourse,
  selectedCourses,
} from '@/types';

export function getPackCourses({
  packObj,
  allStructuredCourses,
  courses,
  selectedCampus,
  type,
}: {
  packObj: PackStructure;
  allStructuredCourses: StructuredCourse[];
  courses: ICourses[];
  selectedCampus: IPlaces | null;
  type: string;
}) {
  const pCourses: ICourse[] = [];
  let tmpLocal: IconTypes = 'online';
  if (type == 'live') {
    tmpLocal = 'live';
  }
  if (type == 'campus') {
    tmpLocal = 'campus';
  }

  let tmpAlias = 'sonans';

  if (selectedCampus && type === 'campus') {
    tmpAlias = selectedCampus.alias;
  }

  if (type === 'live') {
    tmpAlias = 'sondig';
  }

  const tmp_courses = findOfferedPackageCourses(
    packObj.courses,
    tmpLocal,
    allStructuredCourses,
    tmpAlias,
    packObj.title
  );

  let tempC: ICourse[] = [];

  tmp_courses.map((c) => {
    const co = CourseFromId(c.selectedCourse.courseId, c.selectedTime, courses);
    if (co && co !== 'not found') {
      tempC.push(co);
    }
  });

  packObj.courses.map((course) => {
    const c = CourseFromId(course.course_id, course.semester_length_alias, courses);
    if (c && c !== 'not found') {
      pCourses.push(c);
    }
  });

  tempC = tempC.sort((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
    return 0;
  });

  return { tmp_courses, tempC };
}


function structureCourse(packCourse: ICourse) {
  const course: StructuredCourse = {
    courseCode: packCourse.course_code,
    courseTitle: packCourse.title.replace(' live', '').replace(' online', ''),
    labCourses: [],
    places: packCourse.places,
    times: [packCourse.time],
    upgradeCourses: [],
    upgradePlaces: [],
    variants: [],
    vocationalSubject: false,
  };

  return course;
}

// STRUCTURE INCOMING SELECTION
function structureSelection(packCourse: ICourse) {
  const selection: CourseVariants = {
    courseCode: packCourse.course_code,
    courseId: packCourse.course_id,
    courseTitle: packCourse.title.replace(' live', '').replace(' online', ''),
    places: packCourse.places,
    price: packCourse.price,
    priceNetFullyear: packCourse.online_year_price,
    priceNetHalfyear: packCourse.online_half_year_price,
    time: packCourse.time,
    yearHours: packCourse.year_hours,
  };

  return selection;
}

export function addPack({
  replace = true,
  pack,
  selCourses,
  packCourses,
  pCoursesStructured,
}: {
  replace: boolean;
  pack: PackStructure;
  selCourses: selectedCourses[];
  packCourses: ICourse[];
  pCoursesStructured: selectedCourses[];
}) {
  const type = pack.placement;
  const courseArrNoPacks = selCourses.filter((sc) => sc.packCourse !== true);

  let courseArr = [...courseArrNoPacks];
  if (replace) {
    courseArr = [];
  }

  // tempC as input
  packCourses.map((pc) => {
    const course = structureCourse(pc);
    const selection = structureSelection(pc);

    let price = pc.price;
    let semester = pc.time;

    if (type === 'online' && pack.courses) {
      const onlineCourse = pack.courses.filter((p) => p.course_id === pc.course_id);
      if (onlineCourse.length > 0) {
        semester = onlineCourse[0].alternative_type;

        if (semester === 'year' || semester === 'yearspring') {
          price = pc.online_year_price;
        } else {
          price = pc.online_half_year_price;
        }
      }
    }

    // pCoursesStructured = tmp_courses
    if (pc.placement === 'online' && pCoursesStructured) {
      const course = pCoursesStructured.filter((c) => c.courseCode === pc.course_code);
      semester = course[0].selectedSemester;
      if (semester === 'year' || semester === 'yearspring') {
        price = pc.online_year_price;
      } else {
        price = pc.online_half_year_price;
      }
    }

    const pCourseStruct = pCoursesStructured?.filter((c) => c.courseCode === pc.course_code);

    const selectedCourse: selectedCourses = {
      courseCode: course.courseCode,
      type: pc.placement as IconTypes,
      course: course,
      selectedSemester: semester,
      selectedCourse: selection,
      selectedPrice: price,
      selectedTime: pc.time,
      variants: pCourseStruct ? pCourseStruct[0].variants : [],
      labCourses: pCourseStruct ? pCourseStruct[0].labCourses : [],
      upgradeCourses: pCourseStruct ? pCourseStruct[0].upgradeCourses : [],
      upgradeTimes: pCourseStruct ? pCourseStruct[0].upgradeTimes : [],
      packCourse: true,
      pack: pack.alias,
      packTitle: pack.title,
    };

    const index = courseArr.findIndex((ca) => ca.courseCode === pc.course_code);

    if (index !== -1) {
      courseArr[index] = selectedCourse;
    } else {
      courseArr.push(selectedCourse);
    }
  });

  return { courseArr, pack };
}


export function updatePackStatusCourses({
  courses,
  pack,
  structuredCourses,
}: {
  courses: selectedCourses[];
  pack: PackStructure;
  structuredCourses: StructuredCourse[];
}) {
  let packCourses: string[] = [];

  if (pack && pack.courses) {
    packCourses = findPackCourseCodes(pack.courses, structuredCourses);
  }

  const courseArr = [...courses];

  courseArr.forEach((c, i) => {
    if (packCourses.indexOf(c.courseCode) !== -1 || (pack && pack.alias === 'sonanspack')) {
      courseArr[i].packCourse = true;
      courseArr[i].pack = pack.alias;
      courseArr[i].packTitle = pack.title;
    } else {
      courseArr[i].packCourse = false;
      courseArr[i].pack = '';
      courseArr[i].packTitle = '';
    }
  });

  return courseArr;
}
