import { getChosenSemVariants } from '@/helpers/CourseSearch/CourseSearchHelpers';
import { CheckIfFullyearCourse, FindOppositeSemester } from '@/helpers/Functions/CheckSemester';
import { getFirstSem } from '@/helpers/Functions/GetAvailableSemesters';
import { CourseRequirements, ExamArray, PrevExamArray } from '@/helpers/GetText/GetText';
import {
  AppDispatch,
  AutumnSemester,
  CourseVariants,
  IPlaces,
  PlacementType,
  SemesterType,
  SpringSemester,
  StructuredCourse,
  YearSemester,
  selectedCourses,
} from '@/types';
import { AUTUMN_SEMESTERS, SPRING_SEMESTERS, YEAR_SEMESTERS } from '@/utils/constants';
import { setCart, setSelectedPack } from './cart';
import { setCourses } from './courses';

export function findVariantByPlacement(course: StructuredCourse, placement: PlacementType) {
  return course.variants.find((variant) => variant.type === placement);
}

export function getVariantPrice(course: StructuredCourse, selectedPlacement: PlacementType) {
  let price = 0;

  const calculatePrice = (variant: CourseVariants) => {
    return variant.priceNetHalfyear || variant.priceNetFullyear || variant.price || 0;
  };

  // Check if a specific placement is selected
  let mainCourse = findVariantByPlacement(course, selectedPlacement);

  if (mainCourse) {
    price = calculatePrice(mainCourse);
  } else {
    // Default to online placement
    mainCourse = findVariantByPlacement(course, 'online');
    if (mainCourse) {
      price = calculatePrice(mainCourse);
    } else {
      // Check for campus placement if online not found
      mainCourse = findVariantByPlacement(course, 'campus');
      if (mainCourse) {
        price = calculatePrice(mainCourse);
      } else {
        // Check for live placement if campus not found
        mainCourse = findVariantByPlacement(course, 'live');
        if (mainCourse) {
          price = calculatePrice(mainCourse);
        }
      }
    }
  }

  return price;
}

export function getSelectedCourse(
  course: StructuredCourse,
  location: string,
  semester: SemesterType = 'autumn',
  skipFullYearCheck: boolean,
  selectedCampus: IPlaces | null
) {
  const isFullyearCourse = CheckIfFullyearCourse(course.courseCode);

  let sem = isFullyearCourse ? 'year' : semester;

  if (skipFullYearCheck) {
    sem = semester;
  }

  let otherSem = sem === 'autumn' ? 'spring' : 'autumn';

  if (course.courseCode.includes('lab-') && !sem.startsWith('lab_practice')) {
    sem = sem === 'autumn' ? 'lab_practice_autum' : 'lab_practice_spring';
    otherSem = sem === 'lab_practice_autum' ? 'lab_practice_spring' : 'lab_practice_autum';
  } else if (course.courseCode.includes('intro-') && !sem.startsWith('intro')) {
    sem = sem === 'autumn' ? 'introautumn' : 'introspring';
    otherSem = sem === 'introautumn' ? 'introspring' : 'introautumn';
    if (course.times.indexOf(sem) === -1) {
      sem = sem === 'introautumn' ? 'summer_school' : 'newyear_school';
    }
    if (course.times.indexOf(otherSem) === -1) {
      otherSem = otherSem === 'introautumn' ? 'summer_school' : 'newyear_school';
    }
  }

  sem = location === 'online' ? 'netbased' : sem;

  let selCourse = course.variants.filter((variant) => variant.type === location && variant.time === sem);

  if (selCourse.length === 0) {
    selCourse = course.variants.filter((variant) => variant.type === location && variant.time === otherSem);
  }
  if (selCourse.length === 0) {
    selCourse = course.variants.filter((variant) => variant.type === location && variant.time === 'year');
  }

  if (location === 'campus' && selectedCampus) {
    selCourse = course.variants.filter(
      (variant) => variant.type === location && variant.places.includes(selectedCampus.alias) && variant.time === sem
    );
    if (selCourse.length === 0) {
      selCourse = course.variants.filter(
        (variant) =>
          variant.type === location && variant.places.includes(selectedCampus.alias) && variant.time === otherSem
      );
    }
    if (selCourse.length === 0) {
      selCourse = course.variants.filter(
        (variant) =>
          variant.type === location && variant.places.includes(selectedCampus.alias) && variant.time === 'year'
      );
    }
  }

  return selCourse[0];
}

export function selectCourse(
  course: StructuredCourse,
  location: Exclude<PlacementType, ''>,
  selectedCourses: selectedCourses[],
  semester: SemesterType = 'autumn',
  skipFullYearCheck = false,
  selectedCampus: IPlaces | null
) {
  const isFullYear = CheckIfFullyearCourse(course.courseCode);
  const selCourse = getSelectedCourse(course, location, semester, skipFullYearCheck, selectedCampus);

  if (!selCourse) return;

  let selSemester = selCourse.time;

  let selPrice = selCourse.price;

  if ((isFullYear && location === 'online') || (semester === 'year' && location === 'online')) {
    selPrice = selCourse.priceNetFullyear;
    selSemester = 'year';
  } else if (location === 'online') {
    selPrice = selCourse.priceNetHalfyear;
    selSemester = semester ? semester : 'autumn';
  }

  let tmpAlias = 'sonans';

  if (location === 'campus' && selectedCampus) {
    tmpAlias = selectedCampus.alias;
  }

  if (location === 'live') {
    tmpAlias = 'sondig';
  }

  if (location === 'online' && skipFullYearCheck) {
    if (semester === 'year') {
      selPrice = selCourse.priceNetFullyear;
    } else {
      selPrice = selCourse.priceNetHalfyear;
    }
    selSemester = semester;
  }

  const selTime = selCourse.time;

  const variants = course.variants.filter((variant) => variant.places.indexOf(tmpAlias) !== -1);
  const lab = course.labCourses.filter((lab) => lab.places.indexOf(tmpAlias) !== -1);
  const upgrades = course.upgradeCourses.filter((upgrade) => upgrade.places.indexOf(tmpAlias) !== -1);
  const upgradeTimes: string[] = [];
  upgrades.map((u) => {
    upgradeTimes.push(u.time);
  });

  const selectedCourse: selectedCourses = {
    courseCode: course.courseCode,
    type: location,
    course: course,
    selectedSemester: selSemester,
    selectedCourse: selCourse,
    selectedPrice: selPrice,
    selectedTime: selTime,
    variants: variants,
    labCourses: lab,
    upgradeCourses: upgrades,
    upgradeTimes: upgradeTimes,
    packCourse: false,
    pack: '',
    packTitle: '',
  };

  let courseArr = [...selectedCourses];

  const currentCourse = courseArr.find((c) => c.courseCode === course.courseCode);

  if (currentCourse) {
    courseArr = courseArr.filter((c) => c.courseCode !== course.courseCode);
  }

  courseArr = [...courseArr, selectedCourse];

  return courseArr;
}

export function sortCourses(courses: selectedCourses[]) {
  const courseArr = [...courses];
  let yearArr = courseArr.filter((c) => YEAR_SEMESTERS.indexOf(c.selectedSemester as YearSemester) !== -1);
  let autumnArr = courseArr.filter((c) => AUTUMN_SEMESTERS.indexOf(c.selectedSemester as AutumnSemester) !== -1);
  let springArr = courseArr.filter((c) => SPRING_SEMESTERS.indexOf(c.selectedSemester as SpringSemester) !== -1);
  yearArr = yearArr.sort((a, b) => {
    if (a.course.courseTitle.toLowerCase() < b.course.courseTitle.toLowerCase()) return -1;
    if (a.course.courseTitle.toLowerCase() > b.course.courseTitle.toLowerCase()) return 1;
    return 0;
  });
  autumnArr = autumnArr.sort((a, b) => {
    if (a.course.courseTitle.toLowerCase() < b.course.courseTitle.toLowerCase()) return -1;
    if (a.course.courseTitle.toLowerCase() > b.course.courseTitle.toLowerCase()) return 1;
    return 0;
  });
  springArr = springArr.sort((a, b) => {
    if (a.course.courseTitle.toLowerCase() < b.course.courseTitle.toLowerCase()) return -1;
    if (a.course.courseTitle.toLowerCase() > b.course.courseTitle.toLowerCase()) return 1;
    return 0;
  });

  const yearPackArr = yearArr.filter((c) => c.packCourse === true);
  const yearNoPackArr = yearArr.filter((c) => c.packCourse !== true);
  const autumnPackArr = autumnArr.filter((c) => c.packCourse === true);
  const autumnNoPackArr = autumnArr.filter((c) => c.packCourse !== true);
  const springPackArr = springArr.filter((c) => c.packCourse === true);
  const springNoPackArr = springArr.filter((c) => c.packCourse !== true);

  const fullArr = [
    ...yearPackArr,
    ...yearNoPackArr,
    ...autumnPackArr,
    ...autumnNoPackArr,
    ...springPackArr,
    ...springNoPackArr,
  ];

  return fullArr;
}

export function getStudyLoad(course: selectedCourses) {
  const hours = course.selectedCourse.yearHours;
  const percent = Math.trunc((hours / 420) * 100);
  return percent;
}

export function getExamDate(type: string, semester: string, prevSem: boolean, date: string) {
  const firstSem = getFirstSem();
  const secondSem = FindOppositeSemester(firstSem);
  if (semester === firstSem && !prevSem) {
    return date;
  } else if (semester !== firstSem && type !== 'written-exam' && !prevSem) {
    return 'Du får dato av privatistkontoret';
  } else if (semester !== firstSem && type === 'written-exam' && !prevSem) {
    return 'Dato kommer';
  } else if (semester === secondSem && prevSem) {
    return date;
  } else {
    return date;
  }
}

export function translateExamType(examType: string) {
  if (examType === 'oral-exam') {
    return 'Muntlig eksamen';
  } else if (examType === 'oral-lab-exam') {
    return 'Muntlig-praktisk eksamen';
  } else {
    return 'Skriftlig eksamen';
  }
}

export function getExams(course: StructuredCourse) {

  return ExamArray(course.courseCode);
}

export function getCourseRequirements(course: StructuredCourse) {
  const req = CourseRequirements(course.courseCode);
  return req;
}

// Translate the short name of the campus
export const translateShorName = (name: string) => {
  switch (name) {
    case 'Bergen':
      return 'BER';
    case 'Drammen':
      return 'DRM';
    case 'Fredrikstad':
      return 'FRE';
    case 'Kristiansand':
      return 'KRS';
    case 'Oslo':
      return 'OSL';
    case 'Stavanger':
      return 'STV';
    case 'Tønsberg':
      return 'TBG';
    case 'Tromsø':
      return 'TRO';
    case 'Trondheim':
      return 'TRD';
    default:
      return name.slice(0, 3).toUpperCase();
  }
};

// Calculate the study load for the given semester
export function getSemesterStudyLoad(courses: selectedCourses[], semester: string) {
  const shownSemesters = getChosenSemVariants(semester);
  const selected = courses.filter((c) => shownSemesters.indexOf(c.selectedSemester) !== -1);
  const yearCourses = courses.filter((c) => c.selectedSemester === 'year' && c.earlyStart !== true);
  const yearSpringCourses = courses.filter((c) => c.selectedSemester === 'yearspring');
  const earlyStartCourses = courses.filter((c) => c.selectedSemester === 'yearspring' && c.earlyStart === true);
  const exams: string[] = [];

  let warning = false;
  let hours = 0;
  let fullTime = false;

  selected.map((c) => {
    const courseExams = ExamArray(c.courseCode);
    hours = hours + c.selectedCourse.yearHours;
    courseExams.map((e) => {
      exams.push(e.type);
    });
  });

  yearCourses.map((y) => {
    const halfHours = y.selectedCourse.yearHours / 2;
    if (semester === 'autumn') {
      hours += halfHours;
    }

    if (semester === 'spring') {
      hours += halfHours;
      const courseExams = ExamArray(y.courseCode);
      courseExams.map((e) => {
        exams.push(e.type);
      });
    }
  });

  yearSpringCourses.forEach((y) => {
    const halfHours = y.selectedCourse.yearHours / 2;
    hours += halfHours;
    if (semester === 'autumn') {
      const courseExams = ExamArray(y.courseCode);
      courseExams.forEach((e) => {
        exams.push(e.type);
      });
    }
  });

  earlyStartCourses.forEach((e) => {
    if (semester === 'autumn') {
      const courseExams = ExamArray(e.courseCode);
      hours = hours + e.selectedCourse.yearHours;
      courseExams.forEach((e) => {
        exams.push(e.type);
      });
    }
  });

  const percent = Math.trunc((hours / 420) * 100);
  if (percent >= 160) {
    warning = true;
  }
  if (percent < 160) {
    warning = false;
  }

  if (percent >= 100) {
    fullTime = true;
  } else {
    fullTime = false;
  }

  return {
    exams,
    hours,
    percent,
    fullTime,
    warning,
  };
}

// Returns true if the course has only one semester available for the selected placement
export const hasOnlyOneSemester = (
  course: StructuredCourse,
  placement: PlacementType | null,
  selectedCampus: IPlaces | null
) => {
  const semesters = course.variants.filter((variant) => variant.type === placement);

  if (placement === 'online') {
    return false;
  }

  if (placement === 'campus' && selectedCampus) {
    return semesters.filter((variant) => variant.places.includes(selectedCampus.alias)).length === 1;
  }

  if (placement === 'live') {
    return semesters.filter((variant) => variant.time !== 'year').length === 1;
  }

  return semesters.length === 1;
};

export function loadInitialStateFromLocalStorage() {
  return (dispatch: AppDispatch) => {
    const courses = JSON.parse(localStorage.getItem('courses') || '');
    const places = JSON.parse(localStorage.getItem('places') || '');
    const semesters = JSON.parse(localStorage.getItem('semesters') || '');
    const packs = JSON.parse(localStorage.getItem('packCoverage') || '');
    const selectedCourses = JSON.parse(localStorage.getItem('selectedCourses') || '');
    const selectedPack = JSON.parse(localStorage.getItem('selectedPack') || '');

    if (courses) {
      dispatch(setCourses(courses));
    }

    if (places) {
      dispatch(setCourses(places));
    }

    if (semesters) {
      dispatch(setCourses(semesters));
    }

    if (packs) {
      dispatch(setCart(packs));
    }

    if (selectedCourses) {
      dispatch(setCart(selectedCourses));
    }
    if (selectedPack) {
      dispatch(setSelectedPack(selectedPack));
    }
  };
}
