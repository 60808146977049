import { useDispatch, useSelector } from 'react-redux';
import { PlacesDropdown } from '../places/PlacesDropdown';
import { ISchool, schools } from './courseSearch/CourseSearchSemesterIcons';
import { AppDispatch, AppState, StructuredCourse } from '@/types';
import { capitalize, isAvailableOnCurrentCampus, isDisabled } from '@/helpers/courseHelpers';
import { useEffect, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { SelectSemesterOverlay } from './SelectSemesterOverlay';
import { addToCart } from '@/reducers/cart';

interface Props {
  school: ISchool;
  course: StructuredCourse;
}

export function CourseItemButton({ school, course }: Props) {
  const [isSelecingSemester, setIsSelecingSemester] = useState(false);
  const [selectedPlacement, setSelectedPlacement] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [showSelectCampus, setShowSelectCampus] = useState(false);

  const selectedCourses = useSelector((state: AppState) => state.cart.selectedCourses);
  const selectedCampus = useSelector((state: AppState) => state.cart.selectedCampus);

  const dispatch = useDispatch<AppDispatch>();

  // Check if the course is already in the cart and set the selected placement
  useEffect(() => {
    const courseInCart = selectedCourses.find((c) => c.course.courseCode === course.courseCode);
    if (courseInCart) {
      setSelectedPlacement(courseInCart.type);
    } else {
      setSelectedPlacement('');
    }
  }, [selectedCourses, course.courseCode]);

  // Show the warning message for 2 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showWarning) {
      timeout = setTimeout(() => {
        setShowWarning(false);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [showWarning]);

  return (
    <div className={twJoin('pb-1 w-full flex', school.background)}>
      <div className='w-full h-full'>
        <button
          onClick={() => {
            if (isDisabled(course, school.placement, selectedCampus)) {
              setShowWarning(true);
              return;
            }

            const currentSelectedCourse = selectedCourses.find((c) => c.course.courseCode === course.courseCode);

            if (currentSelectedCourse?.packCourse) return;

            if (school.placement === 'campus') {
              if (!selectedCampus) {
                if (!showSelectCampus) {
                  setShowSelectCampus(true);
                  return;
                }
              } else {

                
                
                if (!isSelecingSemester) {
                  setIsSelecingSemester(true);
                  return;
                }
              }
            } else {
              if (!isSelecingSemester) {
                setIsSelecingSemester(true);
              }
              return;
            }

          }}
          className={twMerge(
            'w-full relative aspect-square rounded-lg bg-white border border-neutral-300 shadow-md flex items-center justify-center',
            isDisabled(course, school.placement, selectedCampus) ? 'bg-neutral-200' : ''
          )}>
          {selectedPlacement === school.placement && (
            <div role='button' className={twJoin('h-[80%] w-[80%] rounded-md animate-pop', school.color)}></div>
          )}
          {isSelecingSemester && (
            <SelectSemesterOverlay
              onSelect={(semester) => {
                const currentSelectedCourse = selectedCourses.find((c) => c.course.courseCode === course.courseCode);

                if (currentSelectedCourse?.packCourse) return;

                dispatch(addToCart(course, school.placement, selectedCourses, semester, true, selectedCampus));
                setSelectedPlacement(school.placement);

                setIsSelecingSemester(false);
              }}
              onClose={() => {
                setIsSelecingSemester(false);
              }}
              course={course}
              type={school.placement}
            />
          )}
          {showWarning && (
            <div className='top-0 z-50 right-0 absolute bg-white shadow-md rounded-md p-1 w-32 text-xs border border-neutral-300'>
              {course.courseCode.includes('lab') && school.placement === 'online' ? (
                <span>Lab online er inkludert i fagets onlinekurs.</span>
              ) : (
                <span>
                  Ikke tilgjengelig{' '}
                  {school.placement === 'campus' && selectedCampus
                    ? `i ${selectedCampus?.shortName}`
                    : `på ${capitalize(school.placement)}`}
                  , velg{' '}
                  {capitalize(schools.filter((s) => s.placement !== school.placement).map((s) => s.placement)[0])} eller{' '}
                  {capitalize(schools.filter((s) => s.placement !== school.placement).map((s) => s.placement)[1])}.
                </span>
              )}
            </div>
          )}
          {school.placement === 'campus' && showSelectCampus && (
            <PlacesDropdown
              overlay
              target='#course-search-semester-icons'
              course={course}
              onSelect={(campus) => {
                if (!isAvailableOnCurrentCampus(course, campus)) return;
                setIsSelecingSemester(true);
              }}
              onClose={() => {
                setShowSelectCampus(false);
              }}
            />
          )}
        </button>
      </div>
    </div>
  );
}
