import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Places } from './pages/Places';
import { Container } from './components/ui/Container';
import { Navbar } from './components/ui/Navbar';
import { Verification } from './pages/Verification';
import { twJoin } from 'tailwind-merge';
import { ConfirmPage } from './pages/Confirm';
import { Courses } from './pages/Courses';
import { Form } from './pages/Form';
import {Header} from "@/components/ui/Header.tsx";

function App() {
  const location = useLocation();

  // Remove completed_sale from localStorage
  useEffect(() => {
    const completedSale = localStorage.getItem('completed_sale') || '';

    if (completedSale === 'true') {
      localStorage.removeItem('completed_sale');
    }
  }, []);

  const { pathname } = useLocation();

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className=''>
      <Header />
      <div
        className={twJoin(
          'overflow-x-hidden overflow-y-scroll text-xs md:text-sm tall:h-auto',
          location.pathname === '/' ? 'h-[calc(100svh-136px)] xl:h-[calc(100svh-146px)]' : ''
        )}>
        <Container>
          <Navbar />
          <Routes>
            <Route path='/' element={<Places />} />
            <Route path='/fag' element={<Courses />} />
            <Route path='/paamelding' element={<Verification />} />
            <Route path='/bestill' element={<Form />} />
            <Route path='/bekreftet' element={<ConfirmPage />} />
          </Routes>
        </Container>
      </div>
    </div>
  );
}

export default App;