import { getChosenSemVariants } from '@/helpers/CourseSearch/CourseSearchHelpers';
import FormatPrice from '@/helpers/Functions/FormatPrice';
import { checkIfEarlyAccessCourse } from '@/helpers/GetText/libraries/GetCourseCodes';
import { capitalize } from '@/helpers/courseHelpers';
import { AppState, selectedCourses } from '@/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  course: selectedCourses;
};

export function VerificationLine({ course }: Props) {
  const [semester, setSemester] = useState('');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState(0);

  const { selectedCampus } = useSelector((state: AppState) => state.cart);

  useEffect(() => {
    setTitle(course.selectedCourse.courseTitle);
    setPrice(course.selectedPrice);
    const autumn = getChosenSemVariants('autumn');
    const spring = getChosenSemVariants('spring');
    let semSuffix = ' - halvår';
    if (course.selectedSemester === 'year') {
      semSuffix = ' - helår';
    }

    if (autumn.indexOf(course.selectedSemester) !== -1) {
      setSemester('Høst');
    } else if (spring.indexOf(course.selectedSemester) !== -1 && course.type !== 'online') {
      setSemester('Vår');
    } else if (course.selectedSemester === 'year' && course.earlyStart !== true) {
      setSemester('Helår - start høst');
    } else if (course.selectedSemester === 'yearspring' && course.type !== 'online') {
      setSemester('Helår - start vår');
    } else if (course.selectedSemester === 'yearspring' && course.type === 'online') {
      setSemester('Helår - start vår');
    } else if (course.selectedSemester === 'spring' && course.type === 'online') {
      setSemester('Vår');
    }

    if (course.type === 'online' && course.earlyStart === true) {
      setSemester('Tilgang i dag!');
    }

    if (
      course.type === 'online' &&
      autumn.indexOf(course.selectedSemester) !== -1 &&
      checkIfEarlyAccessCourse(course.courseCode)
    ) {
      setSemester('Høst - tilgang i dag!');
    }

    if (course.type === 'online' && course.selectedSemester === 'year' && checkIfEarlyAccessCourse(course.courseCode)) {
      setSemester('Helår - tilgang i dag!');
    }

  }, [course]);

  return (
    <div className='w-full flex justify-between p-2'>
      <div className='w-full flex flex-col text-left'>
        <p className='text-sm font-semibold'>{title.replace('Matematikk', 'Matte').replace('matematikk', 'matte')}</p>
        <p>
          {course.type === 'campus' && selectedCampus ? selectedCampus.name : capitalize(course.type)} | {semester}
        </p>
      </div>
      {!course.packCourse && <p className='w-full text-right font-semibold'>{FormatPrice(price)},-</p>}
    </div>
  );
}
