import FormatPrice from '@/helpers/Functions/FormatPrice';
import { PackStructure } from '@/types';

type Props = {
  pack: PackStructure;
};

export function PackLine({ pack }: Props) {
  return (
    <div className='w-full flex justify-between items-center bg-primary/50 p-2'>
      <p className='w-full font-bold'>{pack.title}:</p>
      <p className='w-full text-right font-semibold'>{FormatPrice(pack.price)},-</p>
    </div>
  );
}
