import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
}

export function StudyLoadInfo({ className }: Props) {
  return (
    <div
      className={twMerge(
        'bg-primary/30 border-2 text-xs border-primary rounded-lg p-1 text-center flex flex-col items-center justify-center w-full',
        className
      )}>
      <h3 className='font-bold'>Studiebelastning har betydning for lånekassestøtte og arbeidsmengde</h3>
      <p>Over 100% = fulltidsstudent | Under 100% = deltidsstudent</p>
      <p>100% studiebelastning kan sammenlignes med en 100%stilling.</p>
    </div>
  );
}
