import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, IPlaces } from '@/types';
import { translateShorName } from './helpers';

interface placesState {
  places: IPlaces[];
  campusLocations: IPlaces[];
  isLoading: boolean;
  error: string;
}

const initialState: placesState = {
  places: [],
  campusLocations: [],
  isLoading: false,
  error: '',
};

const places = createSlice({
  name: 'places',
  initialState,
  reducers: {
    setPlaces(state, action: PayloadAction<IPlaces[]>) {
      state.places = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setCampusLocations(state, action: PayloadAction<IPlaces[]>) {
      state.campusLocations = action.payload;
    },
  },
});

export const placesReducer = places.reducer;

export const { setPlaces, setIsLoading, setError, setCampusLocations } = places.actions;

export function fetchPlaces() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(setIsLoading(true));
      const { data } = await axios.get<IPlaces[]>(import.meta.env.VITE_API_URL + 'places');

      data.forEach((place) => {
        place.shortName = translateShorName(place.name);
      });
      
      dispatch(setPlaces(data));
      const campusLocations = data.filter(place => place.placement === 'campus')
      dispatch(setCampusLocations(campusLocations));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}
