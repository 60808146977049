import { AppDispatch, AppState } from '@/types';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CourseCartItem } from '../CourseCartItem';
import { removeCourseFromCart } from '@/reducers/cart';
import { StudyLoad } from './StudyLoad';
import { useSemesterCourseContainer } from './useSemesterCourseContainer';

export function SemesterCourseContainer() {
  const { semesters } = useSelector((state: AppState) => state.semester);
  const { studyLoad } = useSelector((state: AppState) => state.cart);

  const semesterHeaderRef = useRef<HTMLHeadElement>(null);
  const semesterCoursesRef = useRef<HTMLDivElement>(null);
  const semesterBackgroundRef = useRef<HTMLDivElement>(null);

  const selectedCourses = useSelector((state: AppState) => state.cart.selectedCourses);
  const dispatch = useDispatch<AppDispatch>();

  // Function to handle the sizing of the semester container. Making sure the background is the same height as the courses.
  function handleSizing() {
    if (semesterHeaderRef.current && semesterCoursesRef.current && semesterBackgroundRef.current) {
      const headerRect = semesterHeaderRef.current.getBoundingClientRect();
      const coursesRect = semesterCoursesRef.current.getBoundingClientRect();
      semesterCoursesRef.current.style.marginTop = `${headerRect.height}px`;
      semesterBackgroundRef.current.style.height = `${coursesRect.height}px`;
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleSizing);

    return () => {
      window.removeEventListener('resize', handleSizing);
    };
  }, []);

  useEffect(() => {
    handleSizing();
  }, [selectedCourses]);

  useSemesterCourseContainer();

  return (
    <section>
      <div className='w-full relative mb-2'>
        <div className='flex gap-1 lg:gap-2 w-full'>
          {semesters &&
            semesters.map((semester, i) => (
              <div key={semester.semesterAlias} className='w-full flex flex-col'>
                <header
                  ref={semesterHeaderRef}
                  key={semester.semesterAlias + '-semester-heading'}
                  className='flex flex-col p-1 bg-primary/60 rounded-t-xl w-full text-center'>
                  <p className='text-sm 2xl:text-xl font-bold'>{semester.semesterTitle}</p>
                  <p className='text-sm 2xl:text-base'>{semester.examString}</p>
                </header>
                <div
                  ref={semesterBackgroundRef}
                  className='border-x-2 min-h-[4rem] lg:min-h[8rem] h-full border-primary/60 relative'></div>
                <StudyLoad studyLoad={studyLoad[i]} />
              </div>
            ))}
        </div>
        <div
          data-testid='cart'
          ref={semesterCoursesRef}
          className='w-full min-h-[4rem] lg:min-h-[8rem] absolute z-20 top-0 grid grid-cols-2 gap-x-0 gap-y-1 lg:gap-y-2 lg:gap-x-0 py-2 px-2'>
          {selectedCourses
            .filter((c) => c.selectedSemester === 'yearspring')
            .map((course, i) => (
              <CourseCartItem
                onRemoveCourse={(course) => {
                  dispatch(removeCourseFromCart(course.course.courseCode));
                }}
                course={course}
                style={{ gridRowStart: (i + 1) }}
                className='col-start-1 col-span-2'
                key={course.course.courseCode}
              />
            ))}
          {selectedCourses
            .filter((c) => semesters[0].availableTimes.includes(c.selectedSemester))
            .map((course, i) => (
              <CourseCartItem
                onRemoveCourse={(course) => {
                  dispatch(removeCourseFromCart(course.course.courseCode));
                }}
                course={course}
                style={{ gridRowStart: selectedCourses.filter((c) => c.selectedSemester === 'yearspring').length + i + 1 }}
                className='col-start-1 mr-3'
                key={course.course.courseCode}
              />
            ))}
          {selectedCourses
            .filter(
              (c) => semesters[1].availableTimes.includes(c.selectedSemester) || c.selectedSemester === 'year'
            )
            .map((course, i) => (
              <CourseCartItem
                onRemoveCourse={(course) => {
                  dispatch(removeCourseFromCart(course.course.courseCode));
                }}
                course={course}
                style={{ gridRowStart: selectedCourses.filter((c) => c.selectedSemester === 'yearspring').length + i + 1 }}
                className='col-start-2 ml-3'
                key={course.course.courseCode}
              />
            ))}
        </div>
      </div>
    </section>
  );
}
