import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICourses, StructuredCourse, AppDispatch } from '@/types';
import { GetStructuredCourses } from '@/helpers/StructureData/StructureData';

interface CoursesState {
  courses: ICourses[];
  structuredCourses: StructuredCourse[];
  isLoading: boolean;
  error: string;
}

const initialState: CoursesState = {
  courses: [],
  structuredCourses: [],
  isLoading: false,
  error: '',
};

const courses = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCourses(state, action: PayloadAction<ICourses[]>) {
      const c = action.payload;
      const structured = GetStructuredCourses(c);
      state.courses = c;
      state.structuredCourses = structured.sort((a,b) => a.courseTitle.localeCompare(b.courseTitle));
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const coursesReducer = courses.reducer;

export const { setCourses, setIsLoading, setError } = courses.actions;

export function fetchCourses() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(setIsLoading(true));
      const { data } = await axios.get(import.meta.env.VITE_API_URL + 'subjects');
      dispatch(setCourses(data));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}
