import {Icon} from "@/components/ui/Icon.tsx";
import {twJoin} from "tailwind-merge";

type ButtonMode = 'nav-left' | 'nav-right' | 'carousel-left' | 'carousel-right'

type Props = {
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    mode?: ButtonMode;
    disabled?: boolean;
    className?: string;
    arrowStyle?: string;
}

function getIconType(mode: string) {
    if (mode === 'nav-right') {
        return "arrow-thin-right"
    } else if (mode === 'carousel-left') {
        return "arrow-full-left"
    } else if (mode === 'carousel-right') {
        return "arrow-full-right"
    }
    return "arrow-thin-left"
}

export function ArrowButton({onClick, className = '', disabled = false, mode = 'nav-left', arrowStyle = ''}: Props) {


    return (
        <div className="py-2 flex">
            <button
                onClick={onClick}
                disabled={disabled}
                className={twJoin(`py-4 px-4 disabled:opacity-50 md:py-2 md:px-2`,
                    mode === 'carousel-left' ? 'border border-black rounded-md bg-primary hover:bg-white' : '',
                    mode === 'carousel-right' ? 'border border-black rounded-md bg-primary hover:bg-white' : '',
                    className
                )}
            >
                <Icon type={getIconType(mode)} className={arrowStyle}/>
            </button>
        </div>
    )
}