import { GetAvailableSemesters } from '@/helpers/Functions/GetAvailableSemesters';
import { setSemester } from '@/helpers/Functions/SetSemester';
import { AppDispatch, AppState, ISemester } from '@/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SemesterState {
  semesters: ISemester[];
}

const initialState: SemesterState = {
  semesters: [],
};

export const semesterSlice = createSlice({
  name: 'semester',
  initialState,
  reducers: {
    setSemesters: (state, action: PayloadAction<ISemester[]>) => {
      state.semesters = action.payload;
    },
  },
});

export const { setSemesters } = semesterSlice.actions;

export function getSemesters() {
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const prevSem = getState().cart.prevSem;

    const sem = GetAvailableSemesters(prevSem);
    const sems = setSemester(sem[0]);

    dispatch(setSemesters(sems));
  };
}
