import { twMerge } from 'tailwind-merge';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export function Container({ children, className = '' }: Props) {
  return <div className={twMerge('w-[95%] md:w-[80%] lg:w-full lg:px-20 2xl:px-0 mx-auto max-w-[1024px]', className)}>{children}</div>;
}
